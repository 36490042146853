/* USER */
export class User{
    id:number;
    name:string;
    email:string;
    username:string;
    password:string;
    role:string;
    params:any;
    id_group:number;
    created:Date;
    customfields:CustomField[];
    id_address:number;
    enabled:number;
    image:string;
    permissions:any;

    constructor(){
        this.name="";
        this.username="";
        this.id=0;
        this.enabled=1;
        this.role="guest";
    }

    public getParam(param,defaultreturn=null){

        this.setParams();
        if(Array.isArray(this.params))
            for(let p of this.params){
                if(p.name==param){
                    return p.value;
                }
            }
        return defaultreturn;
    }

    public setParams(){
        if(!Array.isArray(this.params)){
            try{
                this.params=JSON.parse(this.params)
            }catch{
                this.params=[];
            }
        }
    }

    public isAdmin(){
        if(this.role=='admin' || this.role=='superuser')
            return true;
        return false;
    }

    public isSuperUser(){
        if(this.role=='superuser')
            return true;
        return false;
    }

    public isManager(){
        if(this.role=='manager')
            return true;
        return false;
    }


    public isBackendUser(){
        if(this.isSuperUser() || this.isAdmin() || this.isManager())
            return true;
        return false;
    }

    public isAgent(){
        if(this.role=='agent')
            return true;
        return false;
    }

    public isExternal(){
        if(this.role=='external')
            return true;
        return false;
    }

    public isGuest(){
        if(this.role=='guest')
            return true;
        return false;
    }

}


export class UserGroup{
    id:number;
    name:string;
    title:string;
    description:string;
    permissions:any;
    public:number;
}
export class LogUser{
    id:number;
    id_user:number;
    date:any;
    description:string;
    session:string;
    state:number;
    table:string;
    id_table:number;
    username:string;
}


/* FILTER */
export const FilterMode={
    disabled:0,
    normal:1,
    like:2,
    date:3,
    minmax:4,
    contain:5,
    major:6,
    minor:7,
    majorequal:8,
    minorequal:9,
    custom:10,
    between:11,
    not_contain:12,
    fulltext:13,
    not_ugual:14
}

export class FilterAdvanced{
    
    value:string;
    condition:string;
    table:string;
    id_field:string;
    customfield:CustomField;
    nextCondition:string;
  }

export class Filter<T=string>{
    fields:string[];
    mode:number;
    value:T;
    value2:T;
    nullvalue:T;
    field_fulltext:string;
    enabled:boolean;
    valueIsField:boolean;


    constructor(){
        this.fields=[];
        this.mode=FilterMode.normal;
        this.enabled=true;
        this.valueIsField=false;
        this.value=null;

    }

   
}

export interface StateFilter{
    url:string;
    component:string;
    filters:Filter[];
    advanced:FilterAdvanced[];
  }

/* ORDERING */
export const OrderingMode={
    ascendent:"ASC",
    discendent:"DESC"

}

export class Ordering{
    field:string;
    mode:string;
}

/* TOAST */
export const ToastMode={
    SUCCESS:'succces',
    DANGER:'danger',
    INFO:'info',
    WARNING:'warning'
  };


/* CUSTOMFIELD */
export class CustomField {
    id:number;
    label:string;
    type:string;
    option:any;
    default:string;
    value:any;
    id_customfieldvalue:number;
    ordering:number;
    condition_id:number;
    condition_field:string;
    status:number;
    table:string;
    replicate_to:number;
    tag:string;
    selected:boolean;
    access:string;
    show_label:boolean;
    
}

export class CustomFieldValues {
    id:number;
    id_customfield:number;
    id_table:number;
    table:string;
    value:string;
}

/* TEMPLATE */

export class Origin{
    id:string;
    table:string;
    task:string;
    args:string;
}

export class RequestParam{
    name:string;
    label:string;
    type:string;
    default:string;
    value:string;
}

export class Template{
    id:number;
    name:string;
    body:string;
    format:string;
    table:string;
    status:number;
    origins:string;
    originsArray:Origin[];
    detail:boolean;
    condition:string="";
    inrow:boolean=false;
    description:string="";
    requestparams:string;
    requestparamsArray:RequestParam[];
    source:string;
    section:string;
    filename:string;
    version:number=1;
}

/* COMPANY */

export class Company{
    name:string="";
    address:string="";
    city:string="";
    zip:string="";
    country:string="";
    vat_number:string="";
    tax_code:string="";
    email:string="";
    telephone:string="";
  }

  /* ALARM */

  export class Condition{
    field:string;
    condition:string;
    value:string;
  }

export class Alarm{
    id:number;
    name:string;
    table:string;
    conditionsArray:Condition[];
    conditions:string;
    status:number;
}

export class AlarmResult{
  type:string;
  message:string;
}

export class Action{
    id:number;
    name:string;
    conditions:string;
    action:string;
    table:string;
    conditionsArray:Condition[];
    status:number;
    actionDetail:ActionDetail;

    parseCondition(){
        try{
            let jsonarray=JSON.parse(this.conditions);
            for(let condition of jsonarray){
                let c:Condition=new Condition();
                Object.assign(c,condition);
                this.conditionsArray.push(c);
            }
        }catch{
            this.conditionsArray=[];
        }
    }
}

export class Chat{
    id:number;
    id_user_from:number;
    id_user_to:number;
    date:string;
    status:number;
    message:string;
    user_from:User;
    user_to:User;
    is_read:boolean;
    
}

export interface Field{
    name:string;
    value:string;
  }

export interface ActionDetail{
  action:string;
  table:string;
  fields:Field[];


}

/* LOG */

export class Log{
    id:number;
    date:Date;
    action:string;
    id_user:number;
    table:string;
    id_table:number;
    description:string;
}

/* NOTIFICATION */


export class NotificationMessages{
    id:number;
    message:string;
    name:string;
    gateway:string;
    id_type:number;
    enable:boolean;
    value:any;
    enabled:boolean;

    constructor(name,gateway){
        this.name=name;
        this.gateway=gateway;
    }
}

export class Notification{
    id:number;
    name:string;
    conditions:string;
    conditionsArray:Condition[];
    gateways:NotificationMessages[]=[];
    status:number;
    id_user:number;
    table:string;
    id_table:number;

    constructor(){
        
        this.gateways.push(
            new NotificationMessages("Email","email"),
            new NotificationMessages("SMS","sms"),
            new NotificationMessages("WhatsApp","whatsapp"),
            new NotificationMessages("Push App","app")
        )
    }

}

export interface NotificationLog{
    id:number;
    message:string;
    gateway:string;
    id_address:number;
    preview:string;
    contact:string;
    username:string;
}

export class TemplateEmail{
    id:number;
    name:string;
    address_from:string;
    address_cc:string;
    subject:string;
    body:string;
    
}

export class Form{
    id:number;
    name:string;
    table:string;
    header:string;
    footer:string;
    fields:FormField[];
    sections:any;
    id_template:number;
    id_notification:number;
    
    
}

export class FormField{
    id:number;
    id_form:number;
    name:string;
    label:string;
    type:string;
    params:string;
    defaultvalue:string;
    ordering:number;
    show_list:boolean;
    id_parent:number;

    constructor(){
        this.type="text";
        this.id_parent=0;
    }
}

export class FormValue{
    id:number;
    date:Date;
    id_form:number;
    id_user:number;
    file:string;
    form:Form;
    fields:FormFieldValues[];
    sections:any[];
    signature:string;
    id_table:number;
    table:string;
    id_notification:number;
    id_template:number;
    params:any;

    constructor(){
        this.fields=[];
        this.sections=[];
    }

    getCompiledField(id_section=0){
        let count=0;
        let sections=this.sections;
        for(let s of sections){
          if(s.id==id_section || id_section==0){
            for(let f of s.fields){
              if(f.value=="" && f.required && f.access==1){
                count++;
              }
            }
          }
        }
    
        return count;
      }
}

export class FormFieldValues{
    id:number;
    id_field;
    id_form;
    name:string;
    label:string;
    type:string;
    value:any;

 
}

export class File{
    id:number;
    id_table:number;
    table:string;
    name:string;
    file:string;
    size:number;
    edit:boolean;
    permissions:number;
    username:string;
    role:string;
}

/* CUSTOM FUNCTION */

export class CustomFunction{
    id:number;
    name:string;
    title:string;
    script:string;
    enabled:number;
    table:string;
}