<app-window [title]="'Listino '+record.name">
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item ">
                            <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tabPricelistRental1" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="far fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabPricelistRental2" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Prezzi</span></a>
                        </li>
                    </ul>
                    <div class="pt-2" >
                        <div class="tab-content" id="myTabContent">
                            <!-- GENERALE -->
                            <div class="tab-pane fade show active" id="tabPricelistRental1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Nome</label></div>
                                    <div class="col-lg-4">
                                        <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="record.name" />
                                    </div>
                                    <div *ngIf="user.isSuperUser()" class="col-lg-2 text-right"><label>Immagine</label></div>
                                    <div *ngIf="user.isSuperUser()" class="col-lg-4">
                                        <input type="text" class="form-control form-control-sm" name="image" [(ngModel)]="record.image" />
                                        <!--
                                        <a href="" (click)="fileToUpload.click()"  title="Carica / Modifica">
                                            <img src="{{record.image.imagePath}}" class="img-thumbnail" style="max-height:100px" >
                                        </a>
                                        <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)"> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Validità</label></div>
                                    <div class="col-lg-1 text-lg-right">dal</div>
                                    <div class="col-lg-2">
                                        <input type="date" class="form-control form-control-sm" name="published_start" [(ngModel)]="record.published_start" />
                                    </div>
                                    <div class="col-lg-1 text-lg-right">al</div>
                                    <div class="col-lg-2">
                                        <input type="date" class="form-control form-control-sm" name="published_end" [(ngModel)]="record.published_end" />
                                    </div>
                                    <div class="col-lg-4">
                                        <!-- <button class="btn btn-sm btn-secondary float-right" (click)="">Imposta sempre valida</button> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Descrizione</label></div>
                                    <div class="col-lg-10">
                                        <ckeditor *ngIf="editorOn" [config]="{allowedContent:true}" name="description" tagName="description" [editor]="Editor" [(ngModel)]="record.description"></ckeditor>
                                        <textarea *ngIf="!editorOn" rows="10" class="form-control" name="description"  [(ngModel)]="record.description" ></textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Tipo di tariffa</label></div>
                                    <div class="col-lg-6">
                                        <select class="form-control form-control-sm" name="typeprice" [(ngModel)]="record.typeprice">
                                            <option value="2">In base alla durata (€/ora)</option>
                                            <option value="1">Indifferente dalla durata (€)</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <hr>
                                <h5>Condizioni da verificarsi <small>(tutte contemporaneamente)</small></h5>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Modalità</label></div>
                                    <div class="col-lg-6">
                                        <select class="form-control form-control-sm text-primary" name="type" [(ngModel)]="record.type">
                                            <option value="1">In base agli orari di prelievo e restituzione</option>
                                            <option value="2">In base alla durata minima</option>
                                            
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="record.type==1">
                                    <div class="col-lg-2"><label>Orario di prelievo</label></div>
                                    <div class="col-lg-2">
                                        <input type="text" [class.border-primary]="record.type==1" class="form-control form-control-sm" name="time_start" [(ngModel)]="record.time_start" />
                                    </div>
                                    <div class="col-lg-4 text-lg-right"><label>Orario di restituzione</label></div>
                                    <div class="col-lg-2">
                                        <input type="text" [class.border-primary]="record.type==1" class="form-control form-control-sm" name="time_end" [(ngModel)]="record.time_end" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Durata minima <span>(ore)</span></label></div>
                                    <div class="col-lg-2">
                                        <input type="number" [class.border-primary]="record.type==2" class="form-control form-control-sm" name="duration" [(ngModel)]="record.duration" />
                                    </div>
                                    <div class="col-lg-2"><label>Durata massima <span>(ore)</span></label></div>
                                    <div class="col-lg-2">
                                        <input type="number" [class.border-primary]="record.type==2" class="form-control form-control-sm" name="max_duration" [(ngModel)]="record.max_duration" />
                                    </div>
                                    
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-2"><label>Num. min. partecipanti</label></div>
                                    <div class="col-lg-2">
                                        <input type="number" class="form-control form-control-sm" name="min_partecipants" [(ngModel)]="record.min_partecipants" />
                                    </div>
                                    <div class="col-lg-2 text-lg-right"><label>Num. min. adulti</label></div>
                                    <div class="col-lg-2">
                                        <input type="number" class="form-control form-control-sm" name="min_adults" [(ngModel)]="record.min_adults" />
                                    </div>
                                    <div class="col-lg-2 text-lg-right"><label>Num. min. bambini</label></div>
                                    <div class="col-lg-2">
                                        <input type="number" class="form-control form-control-sm" name="min_children" [(ngModel)]="record.min_children" />
                                    </div>
                                </div>
                            </div>
                            <!-- LISTINO -->
                            <div class="tab-pane fade " id="tabPricelistRental2" role="tabpanel" aria-labelledby="tab1-tab">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let s of record.shops">
                                        <div class="row mb-2">
                                            <div class="col-2 py-2 text-center border-right">
                                                <span>Punto vendita</span>
                                                <select class="form-control form-control-sm" name="id_shop_{{s.id_shop}}" [(ngModel)]="s.id_shop">
                                                    <option *ngFor="let sh of shops" value="{{sh.id}}">{{sh.name}}</option>
                                                </select>
                                                <br>
                                                <span>Ordinamento</span>
                                                <input type="number" class="form-control form-control-sm text-center" name="ordering_{{s.id_shop}}" [(ngModel)]="s.ordering" />
                                                <br>
                                                <button class="btn btn-sm btn-info m-1"  title="Duplica blocco" (click)="duplicateShop(s)"><i class="fa fa-clone"></i></button>
                                                <button class="btn btn-sm btn-danger m-1"  title="Elimina blocco" (click)="deleteShop(s)"><i class="fa fa-trash"></i></button>
                                            </div>
                                            <div class="col-10">
                                                <table class="table table-sm mb-1">
                                                    <thead class="bg-light">
                                                        <th>Tag</th>
                                                        <th>Prezzo<small> (IVA esclusa)</small></th>
                                                        <th>IVA %</th>
                                                        <th class="text-right">Prezzo<small> (IVA inclusa)</small></th>
                                                        <th></th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let p of s.pricesObj">
                                                            <td class="py-0">
                                                                <div class="input-group input-group-sm">
                                                                    <input type="text" class="form-control form-control-sm" style="height:1.5rem;" name="tag_{{s.id_shop}}_{{p.tag}}" id="tag_{{s.id_shop}}_{{p.tag}}" [(ngModel)]="p['tag']">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-secondary btn-xs py-0" (click)="openProductList(p['tag'])" style="height:1.5rem;"  title="Associa a prodotti"><i class="fa fa-link"></i></button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="py-0"><input type="text" class="form-control form-control-sm" style="height:1.5rem;" name="value_{{s.id_shop}}_{{p.tag}}" id="value_{{s.id_shop}}_{{p.tag}}" [(ngModel)]="p['value']" (change)="calculateRow(p)"></td>
                                                            <td class="py-0"><input type="text" class="form-control form-control-sm" style="height:1.5rem;" name="tax_{{s.id_shop}}_{{p.tag}}" id="tax_{{p.tag}}" [(ngModel)]="p.tax" (change)="calculateRow(p)"></td>
                                                            <td class="py-0 "><input type="text" class="form-control form-control-sm text-right" style="height:1.5rem;" name="gross_{{s.id_shop}}_{{p.tag}}" id="gross_{{p.tag}}" [(ngModel)]="p.gross" (change)="calculateRowInv(p)"></td>
                                                            <td class="py-0 "><small *ngIf="record.typeprice==2">€/ora</small><small *ngIf="record.typeprice==1">€</small></td>
                                                            <td class="py-0 text-right">
                                                                <button class="btn btn-xs btn-info ml-1"  title="Imposta la Tag per tutti i punti vendita" (click)="setToAll(s,p)"><i class="fa fa-clone"></i></button>
                                                                <button class="btn btn-xs btn-danger ml-1" title="Elimina"  (click)="deletePrice(s,p)"><i class="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button class="btn btn-xs btn-block btn-success" (click)="addPrice(s)"><i class="fa fa-plus mr-2"></i>&nbsp;Aggiungi prezzo</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <button class="btn btn-success btn-block mt-2" (click)="addShop()"><i class="fa fa-plus-square mr-2"></i>Aggiungi prezzi ad un altro punto vendita</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </form>
</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>


<ng-template #modalProduct>
    <div class="modal-header">
        <b>Elenco prodotti associati</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeProductList()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let p of listProducts">
                <div class="row">
                    <div class="col-2">
                        <img src="{{p.coverImage().imagePath}}" style="height:35px;max-width: 99%;">
                    </div>
                    <div class="col-8">
                        <b *ngIf="p.brand">{{p.brand}}{{p.name}}</b>
                        <small *ngIf="p.codeinternal" class="badge badge-light mx-1">{{p.codeinternal}}</small>
                    </div>
                    <div class="col-2 text-right">
                        <button class="btn btn-danger btn-sm" (click)="unlinkProduct(p)"  title="Disassocia"><i class="fa fa-unlink"></i></button>
                    </div>
                </div>
                <hr class="my-0">
            </li>
        </ul>
        <div class="col-12">
            <a class="mt-2 btn btn-success btn-block btn-sm" (click)="linkProduct()"><i class="fa fa-plus mr-1"></i>Associa altro prodotto</a>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary btn-sm" (click)="closeProductList()">Chiudi</button>
    </div>
</ng-template>