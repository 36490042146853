export class C1Websocket{

    ws:WebSocket;

    connect(url,username,password){
        this.ws=new WebSocket(url);
        this.ws.onopen=(e)=>{
            console.log("C1 WebSocket Connected!");
            this.send({"cmd":"connect","username":username,"password":password});
        }

        this.ws.onmessage=(ev:MessageEvent)=>{
            console.log(ev.data);
        }  

        this.ws.onerror=(e)=>{
            console.log("C1 WebSocket Error "+e);
        }

        this.ws.onclose=(e)=>{
            console.log("C1 WebSocket Closed "+e);
            setTimeout(()=>{
                this.connect(url,username,password);
            },2000);
        }


    }

    send(message){
        this.ws.send(JSON.stringify(message));
    }


   
}