<div class="row">
    <div class="col-12 text-right">
        <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
        <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
        <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
    </div>
    <div class="col-12">
        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
            <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
            
        </div>
        <table class="table" *ngIf="list && list.length>0">
            <thead>
                <th>
                    <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-3">
                        <input  type="checkbox" title="Seleziona tutti"
                        (change)="onChangeAll($event.target.checked)" />
                    </div>
                </th>
                <th>Data</th>
                <th>Importo<br/><small>(iva inclusa)</small></th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let r of list" [class.bg-warning]="verifyDate(r.date) && r.phase==1">
                    <td>
                        <input type="checkbox" #checkrecord id_record="{{r.id}}"
                                (change)="onChange(r.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                />&nbsp;
                    </td>
                    <td>{{r.date | date:'dd/MM/yy'}}</td>
                    <td>{{r.amount*(1+r.tax/100) | currency:"&euro; "}}</td>
                    <td>
                        <button class="btn btn-secondary btn-xs" (click)="openDetail(r)"><i class="fa fa-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>