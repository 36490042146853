<app-windowlist [model]="this">
    <div class="card">
        <div class="row">
            <div class="col-lg-9">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per ruolo ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default text-primary" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="mr-1">
                    <app-windowlistbuttons 
                        [model]="this" 
                        [table]="'users'" 
                        ></app-windowlistbuttons>
                </div>                
            </div>
        </div>
        <form [formGroup]="form" *ngIf="list!=null">
            <table class="table table-sm table-striped text-nowrap m-0" >
                <thead>
                    <th>
                        <div #checkboxselectall class="icheck-primary d-inline mr-3">
                            <input type="checkbox" title="Seleziona tutti"
                            (change)="onChangeAll($event.target.checked);" />
                        </div>
                        ID
                    </th>
                    <th>Nome</th>
                    <th>Descrizione</th>
                    <th></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <ng-container *ngFor="let record of list">
                        <tr>
                            <td>
                                <div class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}" 
                                    (change)="onChange(record.id, $event.target.checked);" [checked]="checkedAll"
                                    />
                                </div>
                                {{record.id}}
                            </td>
                            <td><b>{{record.title}}</b></td>
                            <td><small><i>{{record.description}}</i></small></td>
                            <td class="pr-2">
                                <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                    <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                <button type="button" *ngIf="currentUser.isSuperUser()" title="Elimina" class="btn btn-danger btn-xs float-right ml-1" (click)="confirm_delete(record.id)">
                                    <i class="fa fa-trash"></i></button>
                                <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                    <i class="fas fa-pencil-alt"></i></button>                                
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>