<app-modal [title]="'Ricerca ubicazione esatta'">
    <div class="row">
        <div class="col-12">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control form-control-sm" [readonly]="loading" [(ngModel)]="search" placeholder="inserisci l'indirizzo da cercare..." (key.enter)="geocoding()">
                <div class="input-group-append">
                    <button class="btn btn-sm btn-default" (click)="geocoding()"><i class="fa fa-search mr-1"></i>Cerca</button>
                    <button class="btn btn-sm btn-default" title="La tua posizione" (click)="getCoordinate()"><i class="fas fa-street-view"></i></button>
                </div>
            </div>
        </div>
        <i class="m-5 text-secondary text-center w-100" *ngIf="loading">Ricerca indirizzo in corso...</i>
        <div class="col-12 mt-2">
            <div id="map_container" style="width: 100% ;height: 60vh;"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <small>Lat. <b>{{latitude}}</b>, Long. <b>{{longitude}}</b></small>
        </div>
    </div>
</app-modal>