import { Globals } from "../globals";
import { SystemService } from "../lib/system.service";



export interface ISettingWindow  {}
export class CSettingWindow{

    plugins=[];

    constructor(public systemService:SystemService){};

    closeModal(){
        this['modalWindow'].close();
    }

    getServerPluginsSettings(type){
      this.systemService.getServerPluginsSettings(type).subscribe((items)=>{
        this.plugins=items;
        
      });

    }

    

}

export namespace ISettingWindow {
    type Constructor<T> = {
      new(...args: any[]): T;
      readonly prototype: T;
    }
    const implementations: Constructor<ISettingWindow>[] = [];
    export function GetImplementations(): Constructor<ISettingWindow>[] {
      return implementations;
    }
    export function register<T extends Constructor<ISettingWindow>>(ctor: T) {
      implementations.push(ctor);
      return ctor;
    }

    
  }