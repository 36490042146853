<button class="btn btn-sm btn-default" (click)="openModalFields()"><i class="fas fa-list mr-1"></i>Scegli</button>

<ng-template #modalFields>
    <div class="modal-header">
        <b>Seleziona</b>
    </div>
    <div class="modal-body" style="max-height:80vh; overflow-y:auto">
        <ul class="list-group">
            <li class="list-group-item pointer" *ngFor="let r of list" (click)="selectField(r.Field)">
                {{r.Field}}
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalFields()">Chiudi</button>
    </div>
</ng-template>