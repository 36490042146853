<div class="row">
    <div class="col-12">
        <table class="table">
            <thead>
                <th>Data</th>
                <th>Azione</th>
                <th>Descrizione</th>
                <th>Utente</th>
                <td></td>
            </thead>
            <tbody>
                <tr *ngFor="let l of logs">
                    <td>{{l.created | date:"dd/MM/yy HH:mm:ss"}}</td>
                    <td>{{l.subject}}</td>
                    <td>{{l.description}}</td>
                    <td>{{l.username}}</td>
                    <td>
                        <button class="btn btn-sm btn-secondary" (click)="openDetail(l)"><i class="fa fa-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-success mt-2" (click)="openDetail()"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
    </div>
</div>


<ng-template #modalDetail>
    <div class="modal-header">
        <b>Dettaglio Log</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeDetail()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <label>Oggetto</label>
        <input type="text" class="form-control" [(ngModel)]="log.subject">
        <label>Descrizione</label>
        <textarea  class="form-control" [(ngModel)]="log.description"></textarea>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeDetail()">Annulla</button>
        <button class="btn btn-success" (click)="save()">Salva</button>        
    </div>
</ng-template>