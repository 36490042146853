
<app-window [title]="title">
    <form role="form" id="detailForm" class="mt-1">
        
        <!-- GENERAL-->
        <div class="card card-outline" >
            <div class="card-header p-0 border-bottom-0 bg-light">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                        
                        
                        
                    <li class="nav-item ">
                        <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="profile" aria-selected="false">
                            <i class="far fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Generale</span>
                        </a>
                    </li>

                    <li class="nav-item ">
                        <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="profile" aria-selected="false">
                            <i class="far fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Campi</span>
                        </a>
                    </li>

                    <li class="nav-item ">
                        <a class="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="profile" aria-selected="false">
                            <i class="far fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Altro</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="card-body table-responsive" >
                    <div class="tab-content" id="myTabContent">
                        <!-- GENERALE -->
                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
              
                            
                            <div class="form-group row">
                                <div class="col-2">
                                    <label>Nome</label>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="record.name">    
                                </div>

                                <div class="col-2">
                                    <label>Tabella</label>
                                </div>
                                <div class="col-4">
                                    <app-selecttables [name]="'table'" [value]="record.table" [show_all]="false" (onchange)="record.table=$event;"></app-selecttables>
                                </div>

                            </div>

                            <div class="form-group row">
                                <div class="col-2">
                                    <label>Template di esportazione</label>
                                </div>
                                <div class="col-4">
                                    <select class="form-control form-control-sm" name="id_template" [(ngModel)]="record.id_template">
                                        <option value="0">Nessun template</option>
                                        <option [value]="t.id" *ngFor="let t of templates">{{t.name}}</option>
                                    </select>    
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-2">
                                    <label>Notifica dopo il salvataggio</label>
                                </div>
                                <div class="col-4">
                                    <select class="form-control form-control-sm" name="id_notification" [(ngModel)]="record.id_notification">
                                        <option value="0">Nessun notificazione</option>
                                        <option [value]="n.id" *ngFor="let n of notifications">{{n.name}}</option>
                                    </select>    
                                </div>
                            </div>

                            
                        </div>
                        <!-- CAMPI -->
                        <div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                            <small>
                                <div class="row">
                                    <div class="col-12">
                                <!--
                                    <div class="col-12">

                                        <ul class="nav nav-tabs">
                                            <li class="nav-item" *ngFor="let s of record.sections;let i=index">
                                                <button class="nav-link" [class.active]="tabsection==i" (click)="tabsection=i">{{s.name}}</button>
                                            </li>
                                        </ul>

                                        <div class="tab-content" id="myTabContent">
                                            
                                            <div *ngFor="let s of record.sections;let j=index;" class="tab-pane fade show" [class.active]="tabsection==j">
                                                <ul class="list-group">
                                                    <ng-container *ngFor="let f of s.fields;let i=index" >
                                                        <ng-container *ngIf="f.type!='section'">
                                                            <li class="list-group-item border" >
                                                                <div class="row">
                                                                    <div class="col-10">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Nome</label>
                                                                                    <input class="form-control form-control-sm" type="text" name="{{j}}_{{i}}_name" [(ngModel)]="f.name">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Tipologia</label>
                                                                                    <select class="form-control form-control-sm" name="{{j}}_{{i}}_type" [(ngModel)]="f.type">
                                                                                        <option value="text">Testo</option>
                                                                                        <option value="textarea">Area di testo</option>
                                                                                        <option value="select">Seleziona</option>
                                                                                        <option value="boolean">Si/No</option>
                                                                                        <option value="number">Numero</option>
                                                                                        <option value="separator">Separatore</option>
                                                                                        <option value="date">Data</option>
                                                                                        <option value="time">Orario</option>
                                                                                        <option value="checkbox">Check</option>
                                                                                        <option value="section">Sezione</option>
                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" [class.collapse]="!f['expand']">
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Etichetta</label>
                                                                                    <textarea class="form-control form-control-sm"  name="{{j}}_{{i}}_label" [(ngModel)]="f.label"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Parametri</label>
                                                                                    <textarea class="form-control form-control-sm"  name="{{j}}_{{i}}_params" [(ngModel)]="f.params"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" [class.collapse]="!f['expand']">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <label>Valore di default</label>
                                                                                    <textarea type="text" class="form-control form-control-sm"  name="{{j}}_{{i}}_defaultvalue" [(ngModel)]="f.defaultvalue" ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" [class.collapse]="!f['expand']">
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Mostra valori in anteprima</label>
                                                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_show_list" [(ngModel)]="f.show_list">
                                                                                        <option value="0">No</option>
                                                                                        <option value="1">Si</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label>Dipendenza</label>
                                                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_id_parent" [(ngModel)]="f.id_parent" (change)="moveToSection(f)">
                                                                                        <option *ngFor="let s of record.sections" [ngValue]="s.id">{{s.name}}</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="col-2 text-right">
                                                                        <button class="btn btn-danger btn-xs" (click)="removeField(f)"><i class="fa fa-trash"></i></button>
                                                                        <button class="btn btn-info btn-xs" (click)="moveField(f,'up');"><i class="fas fa-arrow-up" ></i></button>
                                                                        <button class="btn btn-info btn-xs" (click)="moveField(f,'down');"><i class="fas fa-arrow-down" ></i></button>
                                                                        <button class="btn btn-secondary btn-xs" *ngIf="!f['expand']" (click)="f['expand']=true"><i class="fas fa-chevron-down"></i></button>
                                                                        <button class="btn btn-secondary btn-xs" *ngIf="f['expand']" (click)="f['expand']=false"><i class="fas fa-chevron-up"></i></button>
                                                                    
                                                                    </div>
                                                                </div>
                                                                
                                                            </li>
                                                        </ng-container>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        



                                            

                                        </div>

                                        <button class="btn btn-success btn-sm mt-2" (click)="addField()">Aggiungi campo</button>
                                        <button class="btn btn-success btn-sm mt-2 mr-2" (click)="addSection()">Aggiungi sezione</button>
                                        
                                    </div>
                                -->
                                        
                                        <app-fields [sections]="record.sections"></app-fields>
                                    </div>
                                </div>
                            </small>
                        </div>
                        <!-- ALTRO -->
                        <div class="tab-pane" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                            <div class="form-group row">
                                <div class="col-2">
                                    <label>Intestazione</label>
                                </div>
                                <div class="col-12">
                                    <ckeditor *ngIf="editorOnHeader" [config]="{allowedContent:true}" name="header" tagName="header" [editor]="EditorHeader" [(ngModel)]="record.header"></ckeditor>
                                    <textarea *ngIf="!editorOnHeader" rows="20"  name="header" class="form-control form-control-sm" [(ngModel)]="record.header"></textarea>
                             
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-2">
                                    <label>Fine form</label>
                                </div>
                                <div class="col-12">
                                    <ckeditor *ngIf="editorOnFooter" [config]="{allowedContent:true}" name="footer" tagName="footer" [editor]="EditorFooter" [(ngModel)]="record.footer"></ckeditor>
                                    <textarea *ngIf="!editorOnFooter" rows="20"  name="footer" class="form-control form-control-sm" [(ngModel)]="record.footer"></textarea>
                             
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>
