import { Component, OnInit,Input,Output,EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyFiscalCodeService } from '../../services/verify-fiscal-code.service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';

@Component({
  selector: 'app-tax-code',
  templateUrl: './tax-code.component.html',
  styleUrls: ['./tax-code.component.css']
})
export class TaxCodeComponent implements OnInit {
  @ViewChild("modalReverse")
  modalReverse:TemplateRef<any>;
  modalReverseRef;
  
  reverseTaxCode=null;
  @Input()
  record:any={};

  @Input()
  tax_code:string="";

  @Input()
  showReverseBtn=false;

  @Output()
  onTextChange:EventEmitter<string>=new EventEmitter();

  @Output()
  isValid:EventEmitter<boolean>=new EventEmitter();

  @Input()
  options:any={};

  isUpdateFields=false;


  @Input()
  name:string="tax_code";

  tax_code_error:string="";
  valid_tax_code:boolean=false;

  constructor(
    private verifyFiscalCodeService:VerifyFiscalCodeService,
    private modalService:NgbModal

  ) { }

  ngOnInit(): void {
    this.parsingOptions();
  }

  verifyTaxCode():boolean{
   
    if(this.tax_code=='XXXXXXXXXXX') return this.valid_tax_code=true; // salta per intestatario estero
    this.tax_code_error="";
    if(this.tax_code!=undefined){
      this.valid_tax_code=this.verifyFiscalCodeService.validate(this.tax_code);
      if(!this.valid_tax_code)
        this.tax_code_error=this.verifyFiscalCodeService.error_message;

      this.isValid.emit(this.valid_tax_code);
    }
    return this.valid_tax_code;
  }

  onKeyChange(){
    this.onTextChange.emit(this.tax_code.toLocaleUpperCase());
  }


  openModalReverse(){
    this.reverseTaxCode=this.verifyFiscalCodeService.reverse(this.tax_code);
    this.modalReverseRef=this.modalService.open(this.modalReverse);
  }

  closeModalReverse(reaason="cancel"){
    this.modalReverseRef.close(reaason);
  }

  parsingOptions(){
    if(this.options.update){
      if(this.options.update.length>0){
        this.isUpdateFields=true;
      }
      
    }
  }

  updateFields(){
    if(this.options.update && this.record){
      if(this.options.update.length>0){
        for(let u of this.options.update){
          Helper.setValueToRecord(this.record,u.fieldname,this.reverseTaxCode[u.value]);
        }

      }
    }
  }



}
