<div class="modal-header">
    <b><i class="fas fa-user-cog mr-2"></i>Configura</b>
</div>
<div class="modal-body">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==1" (click)="tab=1"  title="Fasi">
                <i class="fas fa-list-ol mr-2"></i>Fasi</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==2" (click)="tab=2"  title="Fasi">
                <i class="fas fa-th-large mr-2"></i>Riepilogo</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent" >
        <div class="tab-pane show active" *ngIf="tab==1">
            <app-dossierphases></app-dossierphases>
        </div>
        <div class="tab-pane show active" *ngIf="tab==2">
            <app-dossieroverviews></app-dossieroverviews>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
</div>
