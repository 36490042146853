import { Globals } from "./globals";
import { EventEmitter, Output } from '@angular/core';
import {LogUser,User,ToastMode} from "./include/structures";
import {UserService} from "./lib/user.service";
import {LogUsersService} from "./lib/logusers.service";
import { Injectable } from '@angular/core';

@Injectable()
export class Access{
    
    /** Evento che viene generato dopo che si fa il login */
    @Output()
    loginEvent:EventEmitter<boolean>=new EventEmitter();

    @Output()
    logoutEvent:EventEmitter<boolean>=new EventEmitter();
    
    /** Percorso di reindirizzamento dopo che è avvenuto il login */
    redirectOnLogin="/";
    
    /** Percorso della pagina di login */
    loginPage="/login";

    /** Ruoli a cui è concesso l'accesso (se non specificato, prendi tutti) */
    allowRole:string[]=[];

    prefix="";



    constructor(
        private logUsersService:LogUsersService,
        private userService:UserService
    ){
      
    }
    
    /** Funzione per effettuare il login */
    onLogin(username="",password="",remember_connected="",redirectAfterLogin="",useStoredAccess=true,redirectToLoginPage=true,redirectAfterLoginEvent=true,showMessage=true):void{

        if(username==undefined || password==undefined)
          return;
        if(useStoredAccess){
          if(remember_connected!=""){
            localStorage.setItem(this.prefix+"_remember_connected",remember_connected);
            localStorage.setItem(this.prefix+"_username",username);
            localStorage.setItem(this.prefix+"_password",password);
          }
          else
            remember_connected=localStorage.getItem(this.prefix+"_remember_connected");

          if(remember_connected=="1"){
            username=localStorage.getItem(this.prefix+"_username");
            password=localStorage.getItem(this.prefix+"_password");
          }
        }

        if(username=="" || username==undefined){
          
          if(redirectAfterLogin)
            Globals.navigation.onNavigate([redirectAfterLogin]);
          else
            if(redirectToLoginPage)
              Globals.navigation.onNavigate([this.loginPage]);
          return;
        }

       
        
        

    
        Globals.setLoading(true);
       
          
        Globals.ws.getToken(username,password,(complete:boolean)=>{
          
         
          if(!complete){
            localStorage.setItem(this.prefix+"_username","");
            localStorage.setItem(this.prefix+"_password","");
            localStorage.setItem(this.prefix+"_remember_connected","");
            if(showMessage)
              Globals.message.showToaster("Username o password errati. Riprovare",ToastMode.WARNING);
            
            if(redirectToLoginPage)
              Globals.navigation.onNavigate([this.loginPage]);
            Globals.setLoading(false);
            Globals.isLogin=false;
          }else{

            //verifica se l'utente è autorizzato a visualizzare l'applicazione
            if(this.allowRole.length>0){
              let role=Globals.user.role;
              if(this.allowRole.indexOf(role)==-1){
                
                Globals.navigation.onNavigate([this.loginPage]);
                if(showMessage)
                  Globals.message.showToaster("Utente non autorizzato",ToastMode.DANGER);
                Globals.setLoading(false);
                Globals.isLogin=false;
                return;
              }
            }

            localStorage.setItem(this.prefix+"_username",username);
            localStorage.setItem(this.prefix+"_password",password);
            Globals.parameters.loadModules(()=>{
                    Globals.navigation.createMenu();
                    Globals.setLoading(false);
                    Globals.isLogin=true;
                    //create session
                    Globals.access.LogUser("Accesso utente "+Globals.user.username);
                    if(Globals.access.redirectOnLogin!="" && redirectAfterLoginEvent)
                       Globals.navigation.onNavigate([Globals.access.redirectOnLogin]);
                    if(this.loginEvent)
                      this.loginEvent.emit(true);
    
            });
    
            
    
          } 
    
          
          
    
        },showMessage);
      
    
        
    }


    login(username,password,loginsuccess,showMessage=true,saveAccess=true){
      Globals.ws.getToken(username,password,(success:boolean)=>{
          if(success){

            if(this.allowRole.length>0){
              let role=Globals.user.role;
              if(this.allowRole.indexOf(role)==-1){
                if(showMessage)
                  Globals.message.showToaster("Utente non autorizzato",ToastMode.DANGER);
                Globals.setLoading(false);
                Globals.isLogin=false;
                return loginsuccess(false);
                
              }
            }
            if(saveAccess){
              Globals.parameters.loadModules(()=>{
                Globals.navigation.createMenu();
                Globals.setLoading(false);
                Globals.isLogin=true;
                Globals.access.LogUser("Accesso utente "+Globals.user.username);
                if(this.loginEvent)
                  this.loginEvent.emit(true);
                return loginsuccess(true);

              });
            }else{
              return loginsuccess(true);
            }

            
            
          }else{
            Globals.isLogin=false;
            if(showMessage)
              Globals.message.showToaster("Username o password errati. Riprovare",ToastMode.WARNING);
            return loginsuccess(false);
          }
          
         
      },showMessage,(onerror)=>{
        Globals.isLogin=false;
            if(showMessage)
              Globals.message.showToaster("Username o password errati. Riprovare",ToastMode.WARNING);
            return loginsuccess(false);
      });
    }






    /** TODO da cancellare */
    /*
    login(username:string,password:string,showAlert=false,onFinish:Function):void{
        Globals.setLoading(true);
        this.userService.login(username,password).subscribe(user=>{
          
          
            Globals.setLoading(false);
          if(typeof(user.id)!="undefined" && user.id>0){
            Globals.user=new User();
              Object.assign(Globals.user,user);
              
              Globals.isLogin=true;
    
              //memorizza il login
              localStorage.setItem("username",username);
              localStorage.setItem("password",password);
              if(this.loginEvent)
                this.loginEvent.emit(true);
    
              onFinish(true);
              
          }else{
            if(showAlert)
              alert("Username o password errati. Riprovare");
            this.logout();
            onFinish(false);
            
          }
        });
    }*/
    
    /** Funzione per effettuare il logout */
    logout(redirectAfterLogout=true):void{
        Globals.user=new User();
        Globals.isLogin=false;
        localStorage.setItem(this.prefix+"_username","");
        localStorage.setItem(this.prefix+"_password","");
        localStorage.setItem(this.prefix+"_remember_connected","");
        if(this.logoutEvent)
            this.logoutEvent.emit(false);

          
        /*if(redirectAfterLogout)
          Globals.navigation.onNavigate([this.loginPage]);*/
    }

    /** Memorizza un'operazione eseguita da un utente */
    LogUser(description:string,state=1,id_table=0,table=""){
        let o:LogUser={} as LogUser;
        o.id_user=Globals.user.id;
        o.description=description;
        o.session=Globals.navigation.session;
        o.state=state;
        o.id_table=id_table;
        o.table=table;
        this.logUsersService.save(o,()=>{},true,false);
    }
}