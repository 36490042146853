import { Component, OnInit, ViewChild } from '@angular/core';
import { ListpluginsComponent } from 'projects/core/src/common/settingwindow/listplugins/listplugins.component';
import { Globals } from 'projects/core/src/globals';
import { Extension } from 'projects/core/src/include/parameters';
import { CSettingWindow, ISettingWindow } from 'projects/core/src/include/settingwindow';
import { SystemService } from 'projects/core/src/lib/system.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})

@ISettingWindow.register

export class DocumentsSettingsComponent extends CSettingWindow implements OnInit {
  
  @ViewChild(ListpluginsComponent)
  listplugins:ListpluginsComponent;



  tab='2';
  module:Extension;

  banks:any=[];
  causals:any=[];
  sectionals:any=[];

  constructor(public systemService:SystemService){
    super(systemService);
    this.getServerPluginsSettings("document");
    
  }

  ngOnInit(): void {
    this.module=Globals.parameters.get("documents");
    this.banks=this.module.getParam("banks",[]);
    this.causals=this.module.getParam("causals",[]);
    this.sectionals=this.module.getParam("sectionals",[]);

  }

  save(){


    

    this.savePlugins();
  }

  savePlugins(){
    this.listplugins.saveServerPlugins();
  }
  
   


}
