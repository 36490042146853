import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

import { Extension } from '../include/parameters'
import { WSService } from '../include/service'
@Injectable({
  providedIn: 'root'
})
export class ModulesService extends WSService<Extension> {

  constructor() { super("modules")}

  install(module,oncomplete){
    var item2db:any=module;
    if(Globals.ws.api_version=="v2")
        item2db={"module":module}

    Globals.ws.send("modules","install",item2db,[],oncomplete);
    
  }

  setDefaultModule(id):Observable<any>{
    return Globals.ws.requestUrl("modules","setDefaultModule",[id]);
  }

  setEnableModule(modulename,status):Observable<any>{
    return Globals.ws.requestUrl("modules","setEnableModule",[modulename,status]);
  }

  getModulesAvailable():Observable<any>{
    return Globals.ws.requestUrl("modules","getModulesAvailable",[]);
  }

  
}
