<div class="modal-header">
    <b>Fattura elettronica</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body text-center">
    <div class="row">        
        <div class="col-lg-3 text-lg-right">
            <label>Visualizza</label>
        </div>
        <div class="col-lg-6">
            <div class="btn-group btn-group-sm w-100">
                <button type="button" class="btn btn-default border btn-sm" 
                    [class.btn-primary]="style==style1" 
                    (click)="updateStyle(style1)">Ministeriale
                </button>
                <button type="button" class="btn btn-default border btn-sm" 
                    [class.btn-primary]="style==style2"
                    (click)="updateStyle(style2)">Assosoftware
                </button>
            </div>
        </div>
    </div>
    <hr>
    <div class="row mb-3" *ngIf="message.length>0">
        <div class="col-12">
            <i class="float-left h-100 pr-2 text-warning fas fa-exclamation-triangle fa-2x"></i>
            <div class="float-left">
                <small>
                    <b>ATTENZIONE:</b><br>
                    Il Sistema ha evidenziati i seguenti errori:
                    <div *ngFor="let m of message"><span class="text-red">&#10008;</span> {{m.message.substring(36)}}</div>
                </small>
            </div>
        </div>
    </div>   
    <iframe id="frame" 
            [src]="sanitizer.bypassSecurityTrustResourceUrl(url)"
            [frameBorder]="0"
            [class.style1]="style==style1" 
            [class.style2]="style==style2" 
            ></iframe>
</div>
<div class="modal-footer">
    <ng-container *ngIf="sdi_status==0 || sdi_status==-1 || sdi_status==3">
        <a type="button" [disabled]="message.length>0" class="btn btn-success" (click)="sendToSdi()" ><i class="far fa-paper-plane mr-2"></i>Invia allo SdI</a>
    </ng-container>    
    <a *ngIf="url_xml.substr(-3)=='xml'" href="{{url_xml}}" target="_blank" [class.btn-outline-primary]="message.length>0" class="btn btn-primary text-white" (click)="close()">
        <i class="fas fa-file-download mr-2"></i>Scarica file XML<span *ngIf="message.length>0"> comunque</span>
    </a>
    <a type="button"  class="btn btn-warning" (click)="downloadPreview()" ><i class="fas fa-print mr-2"></i>Anteprima di stampa</a>
    <a type="button" (click)="close()" class="btn btn-secondary">Chiudi</a>
</div>
