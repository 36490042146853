<app-modal #modal [showFooter]="false" [showHeader]="false">
    <!-- <div class="row">
        <div class="col-12 mb-2">
            <a class="btn btn-outline-primary btn-block">
                <i class="fas fa-qrcode text-purple mr-2"></i><b>Lotteria</b>
                <i *ngIf="false" class="fas fa-check text-success ml-2"></i>
                <i *ngIf="true" class="fas fa-exclamation-triangle text-warning ml-2"></i>
            </a>
        </div>
    </div> -->
    <div id="methodpayment_summary" class="card">
        <!-- <div class="card-header bg-light text-xs p-1 text-center"><b>RIEPILOGO</b></div> -->
        <table class="table table-sm mb-0">
            <thead class="bg-light">
                <th colspan="2" class="text-center"><b>RIEPILOGO</b></th>
            </thead>
            <tbody class="bg-white">
                <tr>
                    <td class="">Da incassare</td>
                    <td class="text-right">{{total | currency:" &euro;&nbsp;"}}</td>
                </tr>
                <tr>
                    <td class="">Incassato<i *ngIf="total_remain == 0" class="fas fa-check text-success ml-2"></i></td>
                    <td class="text-right">{{total_paid | currency:" &euro;&nbsp;"}}</td>
                </tr>
                <tr>
                    <td class="">
                        <span *ngIf="total_remain <= 0">Resto</span>
                        <span *ngIf="total_remain > 0">Rimanenza</span>                        
                    </td>
                    <td class="text-right"[class.text-danger]="total_remain>0">{{total_remain | currency:" &euro;&nbsp;"}}</td>
                </tr>
            </tbody>
        </table>
        <!-- <div class="px-1">
            <div class="row">
                <div class="col-7">Da incassare</div>
                <div class="col-5 text-right">{{total | currency:" &euro;&nbsp;"}}</div>
                <div class="col-7">Incassato<i *ngIf="total_remain == 0" class="fas fa-check text-success ml-2"></i></div>
                <div class="col-5 text-right">{{total_paid | currency:" &euro;&nbsp;"}}</div>
                <div class="col-7">
                    <span class="border-top">                        
                        <span *ngIf="total_remain <= 0">Resto</span>
                        <span *ngIf="total_remain > 0">Rimanenza</span>
                    </span>
                </div>
                <div class="col-5 border-top text-right">
                    <span class="border-top" [class.text-danger]="total_remain>0">{{total_remain | currency:" &euro;&nbsp;"}}</span>
                </div>
            </div>
        </div> -->
    </div>
    
    <div class="card" *ngIf="listMethods.length > 0">
        <table class="table table-sm mb-0">
            <thead class="bg-light">
                <th class="text-left"><b>METODO DI PAGAMENTO</b></th>
                <th class="text-center"><b>IMPORTO PAGATO</b></th>
                <th></th>
            </thead>
            <tbody class="bg-white">
                <tr *ngFor="let m of listMethods">
                    <td class="">{{m.methodpayment}}</td>
                    <td class="text-center">{{m.amount | currency:" &euro;&nbsp;"}}</td>
                    <td><button class="btn text-danger btn-xs float-right" (click)="removePayment(m)" title="Elimina"><i class="fa fa-trash"></i></button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mb-3 text-center" *ngIf="total_remain > 0">
        <div id="methodpayment_value" class="text-xs card p-1 mb-0 bg-success">STA VERSANDO
            <input class="d-none d-lg-block form-control text-xl text-center bg-white" type="number" autofocus #paidInput 
                (select)="$event.target.select()" (focus)="$event.target.select()"  name="paid" [(ngModel)]="paid"/>
            <div class="d-block d-lg-none bg-black text-xl text-center">{{paid | currency:"&euro;&nbsp;"}}</div>
        </div>
    </div>
    <div class="row" *ngIf="total_remain > 0">
        <div class="col-lg-4 mb-2 pr-lg-0">
            <a class="wave py-3 btn btn-primary btn-block" (click)="selectMethod('Contanti');"><b>Contanti</b><small class="KeyFunction ml-2 d-none d-lg-inline">(F2)</small></a>
        </div>
        <div class="col-lg-4 mb-2">
            <a class="wave py-3 btn btn-primary btn-block" (click)="selectMethod('POS');"><b>POS</b><small class="KeyFunction ml-2 d-none d-lg-inline">(F4)</small></a>
        </div>
        <div class="col-lg-4 mb-2 pl-lg-0">
            <a class="wave py-3 btn btn-primary btn-block " (click)="selectMethod('Tickets');"><b>Tickets</b><small class="KeyFunction ml-2 d-none d-lg-inline">(F6)</small></a>
        </div>
    </div>   
    <div class="" *ngIf="total_remain <= 0">
        <a class="wave btn btn-success btn-block text-xl" (click)="confirm()" ><i class="fas fa-print mr-2"></i>Stampa<small class="KeyFunction ml-2 d-none d-lg-inline">(F8)</small></a>
        <!-- <a class="wave btn btn-primary btn-block text-xl"><i class="fas fa-share-alt mr-2"></i>Condividi</a> -->
    </div>
    <div class="mt-2">
        <a class="wave btn btn-secondary btn-block" (click)="modal.close()">Annulla<small class="KeyFunction ml-2 d-none d-lg-inline">(ESC)</small></a>
    </div>
    <app-keyboard [showFunctions]="false" (onDigit)="paid=$event" ></app-keyboard>
</app-modal>