<app-windowlist [model]="this">
    
  <div class="card elevation-2 p-1">
    <div class="row">
        <div class="pr-lg-0" [class.col-12]="id_address>0 " [class.col-lg-6]="id_address==0">
            <div class="row">
                <div class="col-lg-5 py-1">
                    <div class="input-group input-group-sm" >
                        <input type="text" name="table_search" class="form-control form-control-sm" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append input-group-append-sm">
                            <button type="submit" class="btn btn-sm btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button type="button" class="btn btn-sm btn-default text-danger"   title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3 py-1">
                    <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()">
                        <option value="0">Tutti</option>
                        <option *ngFor="let t of type_shops" [ngValue]="t.id">{{t.name}}</option>
                    </select>
                </div>
                <div class="col-6 col-lg-4 py-1" *ngIf="mode!='modal'">
                    <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-3">
                        <input  type="checkbox" title="Seleziona tutti"
                        (change)="onChangeAll($event.target.checked)" />
                    </div>
                    <app-windowlistbuttons 
                    [model]="this" 
                    [table]="'shops'" 
                    [openDetailExtra]="{'name':'id_address','value':id_address}" 
                    [funs]=""
                    ></app-windowlistbuttons>
                    <!-- <button  title="Cestina" *ngIf="filter_status.value != '2'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="far fa-trash-alt"></i></button>
                    <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                    <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                    <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                    <button  title="Aggiungi" (click)="openDetail(null,{'name':'id_address','value':id_address})" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button> -->
                </div>
                <div class="col-12 mb-1">
                    <small>
                        <input class="mx-2" type="checkbox" name="filter_gps"  [(ngModel)]="filter_gps.enabled" (ngModelChange)="getItems()">
                        <i>Senza coordinate</i>
                    </small>
                </div>
            </div>
            
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let record of list">
                    <div *ngIf="!filter_gps.enabled || (filter_gps.enabled && (!record.latitude || !record.longitude))" class="row pt-1 border-top" >
                        <div class="d-none d-lg-block col-1 p-0">
                            <div class="leaflet-marker-icon extra-marker extra-marker-circle-blue-dark"><i class="initials">{{record.name.substr(0,3)}}</i></div>                            
                        </div>
                        <div class="col-9 col-lg-8 pl-0">
                            <a [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record);" class="pointer" >
                                <h6 class="mb-0">{{record.name}}
                                    <i *ngIf="record.id_inventory>0" toggle="tooltip" title="la sede ha un magazzino" class="fa fa-boxes ml-1"></i>
                                    <small *ngIf="record.type && record.type_params!='default'" class="ml-1"><i>({{record.type}})</i></small>
                                    <span class="badge badge-warning ml-2" *ngIf="record.addressItem.status==2"><i class="fas fa-exclamation-triangle mr-1"></i>Anagrafica cestinata</span>
                                </h6>
                            </a>
                            <small *ngIf="record.activity">{{record.activity}}<br/></small>
                            <small><span *ngIf="record.address">{{record.address}},</span> {{record.city}} {{record.country}}</small> <span *ngIf="record.latitude==0" class="badge badge-warning ml-2">NO GPS</span><br>
                        </div>
                        <div class="col-3 pl-0 pr-2">
                            <input type="checkbox" #checkrecord id_record="{{record.id}}"    title="{{record.id}}"
                                    (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll" />
                            <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                <i class="fas fa-pencil-alt"></i></button>
                            <button type="button" *ngIf="mode!='modal'"  (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right ml-1">
                                <i class="far fa-paper-plane"></i></button>
                            <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right ml-1" (click)="selectRecord(record)">
                                <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
        <div class="d-none d-lg-block col-lg-6" *ngIf="id_address==0" >
            <div id="map_shops" class="w-100 h-100" *ngIf="id_address==0" style="min-height:80vh"></div>
            <div id="searchbarmap">
                <div class="input-group">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="search" placeholder="inserisci l'indirizzo da cercare..." (keyup.enter)="geocoding()">
                    <div class="input-group-append">
                        <a class="btn btn-secondary btn-sm" (click)="geocoding()"><i class="fa fa-search"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer py-1">
        <app-pagination [model]="this" ></app-pagination>
    </div>
  </div>
</app-windowlist>