<div class="input-group input-group-sm">
    <input #taxe_code type="text" class="form-control form-control-sm" 
            [class.is-invalid]="tax_code && !verifyTaxCode()" 
            [class.is-valid]="verifyTaxCode()" required="required"
            name="{{name}}" maxlength="16" #{{name}}="ngModel"  
            [(ngModel)]="tax_code" 
            pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$" 
            title="{{tax_code_error}}"  
            (keyup)="onKeyChange()" >
    <div class="input-group-append" *ngIf="showReverseBtn">
        <button class="btn btn-sm" [disabled]="!valid_tax_code" title="Estrai dati dal Codice Fiscale" (click)="openModalReverse()"><i class="fas fa-info"></i></button>
    </div>
</div>


<ng-template #modalReverse>
    <div class="modal-header">
        <b>Dati estratti dal codice fiscale</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalReverse()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center my-3">
                <h2 class="">{{reverseTaxCode['cf']}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">Sesso</div>
            <div class="col-6"><b>{{reverseTaxCode['gender']}}</b></div>
        </div>
        <div class="row">
            <div class="col-6 text-right">Data di nascita</div>
            <div class="col-6"><b>{{reverseTaxCode['birthday'] | date:"dd-MM-YYYY"}}</b></div>
        </div>
        <div class="row">
            <div class="col-6 text-right">Comune di nascita</div>
            <div class="col-6"><b>{{reverseTaxCode['birthplace']}}</b></div>
        </div>
        <div class="row">
            <div class="col-6 text-right">Provincia di nascita</div>
            <div class="col-6"><b>{{reverseTaxCode['birthplaceProvincia']}}</b></div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="isUpdateFields" class="btn btn-sm btn-primary btn-block" (click)="updateFields()">Aggiorna campi del record</button>
        <button class="btn btn-sm btn-secondary btn-block" (click)="closeModalReverse()">Chiudi</button>
    </div>
</ng-template>