import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'projects/core/src/include/model';
import { Condition, Notification, NotificationMessages } from 'projects/core/src/include/structures';
import { NotificationService } from 'projects/core/src/lib/notification.service';

@Component({
  selector: 'lib-detail',
  standalone: false,
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.css'
})
export class SettingNotificationDetailComponent extends Model<Notification> implements OnInit,AfterViewInit  {
  tab=1;
  

  constructor(
      private route1: ActivatedRoute,
      private notificationService: NotificationService,
      ) {
        super(route1,notificationService); 
        
        this.modulename="notifications";
    }
  
    foreign=false;
    isNew=false;
    ngOnInit (): void {
  
      this.title = 'Template notifica';
      this.record=new Notification();
      this.record.status=1;
      
      super.ngOnInit.apply(this, arguments); 
      
      if(this.mode!="modal")
        this.route1.params.subscribe((params) => this.id = params.id);
      
      this.afterGetItem=()=>{
       
        this.record.conditionsArray=[];
        if(this.record.id>0){
          if(this.record.conditions!="")
            this.record.conditionsArray=JSON.parse(this.record.conditions);


          if(this.record.gateways==undefined)
            this.record.gateways=[];
          //verifica che tutti i gateways abilitati siano presenti
          const gateway_email=this.record.gateways.find((x)=>x.gateway=="email");
          if(!gateway_email) this.record.gateways.push(new NotificationMessages("Email","email"));
          if(this.module.getParam("sms_enable")){
            const gateway_sms=this.record.gateways.find((x)=>x.gateway=="sms")
            if(!gateway_sms) this.record.gateways.push(new NotificationMessages("SMS","sms"));
          }
          if(this.module.getParam("whatsapp_enable")){
            const gateway_whatsapp=this.record.gateways.find((x)=>x.gateway=="whatsapp")
            if(!gateway_whatsapp) this.record.gateways.push(new NotificationMessages("Whatsapp","whatsapp"));
          }
          if(this.module.getParam("app_enable")){
            const gateway_app=this.record.gateways.find((x)=>x.gateway=="app")
            if(!gateway_app) this.record.gateways.push(new NotificationMessages("Push (App)","app"));
          }

          //imposta i gateway vuoti
          


          /*
          if(this.record.gateways.length>0){
            for(let i=0;i<this.record.gateways.length;i++)
              switch(this.record.gateways[i].gateway){
                case "email":
                  this.gateway_email=this.record.gateways[i];
                  break;
                case "sms":
                  this.gateway_sms=this.record.gateways[i];
                  break;
                case "whatsapp":
                  this.gateway_whatsapp=this.record.gateways[i];
                  break;
                case "app":
                  this.gateway_app=this.record.gateways[i];
                  break;
                
                }
            
          }else{
            
            this.record.gateways.push(this.gateway_email);

            
            this.record.gateways.push(this.gateway_sms);

           
            this.record.gateways.push(this.gateway_whatsapp);

            
            this.record.gateways.push(this.gateway_app);


          }
            */
        }
      }
      
      this.getItem();
    }



  addCondition():void{
    let c:Condition={} as Condition;
    if(this.record.conditionsArray==undefined)
      this.record.conditionsArray=[];

    this.record.conditionsArray.push(c);
  }

  removeCondition(c:Condition):void{
    for(let i=0;i<this.record.conditionsArray.length;i++)
      if(this.record.conditionsArray[i]==c)
        this.record.conditionsArray.splice(i,1);
  }
}
