<app-windowlist [model]="this">
    <div class="card">
            <div class="row">
                <div class="col-9">
                    <div class="input-group input-group-info input-group-sm p-1">
                        <input type="text" name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome modulo" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button type="button" class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-right">
                    <button class="btn btn-success btn-sm m-1" (click)="add()"><i class="fa fa-plus"></i></button>
                </div>
            </div>
            <form [formGroup]="form" *ngIf="list!=null">
                <table class="table table-nowrap  table-sm table-striped m-0 text-sm">
                    <thead>
                        <th></th>
                        <th>Nome</th>
                        <th>Permessi</th>
                        <th class="text-center">Mostra nel menu</th>
                        <th class="text-center">Abilitato</th>
                        <th class="text-center">Ordinamento</th>
                        <th class="text-center">Default</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of list">
                            <td>
                                <span *ngIf="record.icon"><i class="text-gray {{record.icon}} fa-2x mr-2"></i></span>
                            </td>
                            <td>
                                <a class="pointer" data-toggle="modal" data-target="#modalRule" (click)="setRule(record)"><b>{{record.label}}</b></a>
                                <a *ngIf="!record.label" class="pointer" data-toggle="modal" data-target="#modalRule" (click)="setRule(record)"><i>({{record.modulename}})</i></a>
                            </td>
                            <td><small>{{record.permissions}}</small></td>
                            <td class="text-center">
                                <div *ngIf="record.type==1 || record.type==2" class="badge badge-secondary badge-left px-2">Backend</div>
                                <div *ngIf="record.type==3" class="badge badge-primary px-2">Frontend</div>
                                <div *ngIf="record.type==4" class="badge badge-secondary badge-left px-2">Backend</div>
                                <div *ngIf="record.type==4" class="badge badge-primary badge-right px-2">Frontend</div>
                                <div class="badge border badge-right">
                                    <small *ngIf="record.type==1">MAIN</small>
                                    <small *ngIf="record.type==2">SETTINGS</small>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="pointer"  title="Doppio Click per abilitatre/disabilitare">
                                    <i *ngIf="record.status==1" (dblclick)="setStatusModule(record)" class="text-success fa-2x fa fa-check"></i>
                                    <i *ngIf="record.status==2" (dblclick)="setStatusModule(record)" class="text-gray fa-2x fa fa-check"></i> 
                                </div>
                            </td>
                            <td class="text-center">
                                <h4 class="mb-0" *ngIf="!record['orderingEditMode']" (dblclick)="record['orderingEditMode']=true">{{record.ordering}}</h4>
                                <div class="form-group form-group-sm mb-0" *ngIf="record['orderingEditMode']">
                                    <div class="input-group input-group-sm">
                                        <input type="form-control form-control-sm text-center" name="ordering" [(ngModel)]="record.ordering" style="width:50px">
                                        <div class="input-group-append input-group-append-sm">
                                            <button class="btn btn-success btn-sm" (click)="update(record);record['orderingEditMode']=false;"><i class="fa fa-check"></i></button>
                                            <button class="btn btn-danger btn-sm" (click)="record['orderingEditMode']=false;"><i class="fa fa-times"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="pointer"  title="Doppio Click per default">
                                    <i *ngIf="record.defaultmodule" class="text-success fa fa-2x fa-check"></i>
                                    <i *ngIf="!record.defaultmodule" (click)="setDefaultModule(record)" class="text-danger fa fa-2x fa-times"></i>
                                </div>
                            </td>
                            <td>
                                <button type="button"   title="Modifica" class="btn btn-secondary btn-xs float-right ml-1" data-toggle="modal" data-target="#modalRule" (click)="setRule(record)">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        <div class="card-footer clearfix py-1">
            <!-- <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small> -->
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>

     
<div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header bg-light">
                <b>Dettaglio modulo</b>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-dismiss="modal" >
                      <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body" *ngIf="selectedModule">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="modulename" [(ngModel)]="selectedModule.modulename"/>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label>Etichetta</label>
                            <input class="form-control form-control-sm" type="text" name="label" [(ngModel)]="selectedModule.label"/>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label>Stato</label>
                            <div class="input-group" >
                                <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedModule.status">
                                    <option value="0">Non pubblicato</option>
                                    <option value="1">Pubblicato</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ordine</label>
                            <input class="form-control form-control-sm" type="number" name="ordering" [(ngModel)]="selectedModule.ordering"/>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label>Mostra nel menù</label>
                            <div class="input-group" >
                                <select name="type" class="form-control form-control-sm" name="type" [(ngModel)]="selectedModule.type">
                                    <option value="0">No</option>
                                    <option value="1">Backend (sidebar)</option>
                                    <option value="2">Backend (Menu amministrativo)</option>
                                    <option value="4">Backend + Frontend</option>
                                    <option value="3">Frontend</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label>Icona</label>
                            <div class="input-group input-group-sm">                                
                                <input class="form-control form-control-sm" type="text" name="icon" [(ngModel)]="selectedModule.icon"/>
                                <div class="input-group-append">
                                    <span class="input-group-text bg-white"><i class="{{selectedModule.icon}}"></i></span>
                                </div>
                            </div>                    
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="form-group">
                            <label>Permessi</label>
                            <div class="pt-2">
                                <ng-container *ngIf="user.role=='superuser'">
                                    <input class="ml-2" type="checkbox" name="role_permission_superuser" [checked]="checkPermissions('superuser')" (click)="setPermissions('superuser');">&nbsp;SU
                                </ng-container>
                                <input class="ml-2" type="checkbox" name="role_permission_admin" [checked]="checkPermissions('admin')" (click)="setPermissions('admin');">&nbsp;Amministratore
                                <input class="ml-2" type="checkbox" name="role_permission_manager" [checked]="checkPermissions('manager')" (click)="setPermissions('manager');">&nbsp;Manager
                                <input class="ml-2" type="checkbox" name="role_permission_agent" [checked]="checkPermissions('agent')" (click)="setPermissions('agent');">&nbsp;Agente
                                <input class="ml-2" type="checkbox" name="role_permission_external" [checked]="checkPermissions('external')" (click)="setPermissions('external');">&nbsp;Esterno
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-top py-2" *ngIf="isArray(selectedModule.params)">
                    <div class="col-12">
                        <h5>Altri parametri</h5>
                    </div>
                    <table class="table table-striped">
                        <ng-container *ngFor="let p of selectedModule.params">
                            <tr *ngIf="p.type=='separator'">
                                <td colspan="2"><b>{{p.label}}&ensp;</b></td>
                            </tr>
                            <tr *ngIf="p.type!='separator'">
                                <td><label>{{p.label}}&ensp;</label></td>
                                <td>                                    
                                    <div class="input-group input-group-sm" *ngIf="p.type=='type'">
                                        <input type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                        <div class="input-group-append">
                                            <button class="btn btn-default" (click)="searchType(p)"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                    <div class="input-group input-group-sm" *ngIf="p.type=='notification'">
                                        <input type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                        <div class="input-group-append">
                                            <button class="btn btn-default" (click)="searchNotification(p)"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                    <div class="input-group input-group-sm" *ngIf="p.type=='notificationrules'">                                        
                                        <table class="table">
                                            <thead>
                                                <th>Tipologia</th>
                                                <th>Notifica</th>
                                                <th>Giorni prima dalla data del record</th>
                                                <th>Condizione (SQL)</th>
                                                <th></th>
                                            </thead>
                                            <tr *ngFor="let r of p.value">
                                                <td>                                                 
                                                    <select class="form-control form-control-sm" [(ngModel)]="r.type" >                                                        
                                                        <option *ngFor="let t of types" [ngValue]="t.id">{{t.name}}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select class="form-control form-control-sm" [(ngModel)]="r.notification" >
                                                        
                                                        <option *ngFor="let n of notifications" [ngValue]="n.id">{{n.name}}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="r.days">
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="r.condition">
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-sm btn-danger" (click)="deleteNotificationRule(p,r)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </table>
                                        <button type="button" class="btn btn-sm btn-success" (click)="addNotificationRule(p)"><i class="fa fa-plus"></i></button>
                                    </div>
                                    <input *ngIf="p.type=='number'" type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                    <input *ngIf="p.type=='text'" type="text" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                    <select *ngIf="p.type=='boolean'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                                        <option value="true">Si</option>
                                        <option value="false">No</option>
                                    </select>
                                    <ngx-tags-input *ngIf="p.type=='multiple'" displayField="item" class="form-control form-control-sm" [(ngModel)]="p.value" name="value"></ngx-tags-input>
                                    <select *ngIf="p.type=='select'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                                        <option *ngFor="let o of p.params" value="{{o.value}}">{{o.label}}</option>
                                    </select>                                    
                                    <table *ngIf="p.type=='table'" class="table table-nowrap table-sm table-head-fixed table-hover m-0 text-sm border">
                                        <thead class="">
                                            <th *ngFor="let f of p.fields">{{f.label}}</th>
                                            <th><button type="button" class="btn btn-xs btn-success float-right" (click)="addTableRecord(p);"><i class="fa fa-plus mr-2"></i>Aggiungi</button></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let v of p.value">
                                                <td *ngFor="let f of p.fields">
                                                    <input class="form-control form-control-sm" type="text" name="{{f.name}}" [(ngModel)]="v[f.name]"/>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-xs btn-danger float-right" (click)="removeTableRecord(p,v);"><i class="fa fa-trash-alt mr-2"></i>Elimina</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    <!--
                    <ng-container *ngFor="let p of selectedModule.params">
                        <ng-container *ngIf="p.type=='separator'">
                            <div class="col-12 mt-2 bg-light text-gray">
                                <b>{{p.label}}&ensp;</b>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="p.type!='separator'">
                            <div  class="col-2"   [class.col-3]="p.type=='text'" [class.col-12]="p.type=='table'">
                                <small>{{p.label}}&ensp;</small>
                                <div class="input-group input-group-sm" *ngIf="p.type=='type'">
                                    <input type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                    <div class="input-group-append">
                                        <button class="btn btn-default" (click)="searchType(p)"><i class="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <div class="input-group input-group-sm" *ngIf="p.type=='notification'">
                                    <input type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                    <div class="input-group-append">
                                        <button class="btn btn-default" (click)="searchNotification(p)"><i class="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <input *ngIf="p.type=='number'" type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                <input *ngIf="p.type=='text'" type="text" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                                <select *ngIf="p.type=='boolean'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </select>
                                <ngx-tags-input *ngIf="p.type=='multiple'" displayField="item" class="form-control form-control-sm" [(ngModel)]="p.value" name="value"></ngx-tags-input>
                                <select *ngIf="p.type=='select'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                                    <option *ngFor="let o of p.params" value="{{o.value}}">{{o.label}}</option>
                                </select>
                                
                                <table *ngIf="p.type=='table'" class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                    <thead class="bg-light">
                                        <th *ngFor="let f of p.fields">{{f.label}}</th>
                                        <th><button type="button" class="btn btn-xs btn-success float-right" (click)="addTableRecord(p);"><i class="fa fa-plus mr-2"></i>Aggiungi</button></th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let v of p.value">
                                            <td *ngFor="let f of p.fields">
                                                <input class="form-control form-control-sm" type="text" name="{{f.name}}" [(ngModel)]="v[f.name]"/>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-xs btn-danger float-right" (click)="removeTableRecord(p,v);"><i class="fa fa-trash-alt mr-2"></i>Elimina</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                    </ng-container>
                -->
                </div>

                <!-- 
                <div class="row mt-2" *ngIf="user.role=='superuser'">
                    <div class="col-12" >
                        <button class="btn btn-xs btn-secondary" (click)="enable_edit_params=true">Abilita modifiche parametri</button>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="enable_edit_params && isArray(selectedModule.params)">
                    <div class="col-12">
                        <json-editor [data]="selectedModule.params" (change)="json_params=$event"></json-editor>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-xs" style="background-color: #3883fa;" (click)="selectedModule.params=json_params">Applica</button>
                
                    </div>
                </div>
                
                <div class="row mt-4" *ngIf="user.role=='superuser'">
                    <div class="col-12" >
                        <button class="btn btn-xs float-right" *ngIf="!enable_edit_params" (click)="enable_edit_params=true">Modifica parametri</button>
                    </div>
                    <div class="col-12" *ngIf="enable_edit_params">
                        <label>Parametri</label>
                        <json-editor [data]="selectedModule.params" (change)="json_params=$event"></json-editor>
                        <button class="btn btn-xs text-white" style="background-color: #3883fa;" (click)="selectedModule.params=json_params;enable_edit_params=false">Applica modifiche</button>
                    </div>
                </div>
                -->

                <div class="row mt-2" *ngIf="user.role=='superuser'">                    
                    <div class="col-12">
                        <h6>
                            <span style="color: #3883fa;">Visualizzatore JSON</span>
                            <button class="btn btn-xs text-white float-right" style="background-color: #3883fa;" (click)="selectedModule.params=json_params">Applica</button>
                        </h6>
                        <json-editor [data]="selectedModule.params" (change)="json_params=$event"></json-editor>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="user.role=='superuser'">
                    <div class="col-12">
                        <button class="btn btn-sm btn-outline-secondary float-right ml-1" (click)="generateManifest()">Crea manifest per il modulo</button>
                        <button class="btn btn-sm btn-outline-secondary float-right" (click)="updateDBModule()">Aggiorna le tabelle del modulo</button>                        
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-2"></i>Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Annulla</button>
            </div>
        </div>
    </div>
</div>


<ng-template #listModules>
    <div class="modal-header">
        <b>Aggiungi modulo</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeListModules()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body" style="max-height:80vh; overflow-y:auto">
        <table class="table table-sm mb-0">
            <thead>
                <th>Titolo</th>
                <th>Identificativo</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let r of mlist">
                    <td><i class="text-primary {{r.icon}} mr-2"></i><b>{{r.label}}</b></td>
                    <td><small><i>{{r.modulename}}</i></small></td>
                    <td class="text-right"><button class="btn btn-success btn-xs" (click)="installModule(r)">Installa <i class="fa fa-chevron-right ml-1"></i></button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary btn-sm" (click)="closeListModules()">Chiudi</button>
    </div>
</ng-template>