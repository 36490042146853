<app-window [title]="title" >
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group form-group-sm">
                    <label>Nome</label>
                    <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="record.name"/>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group form-group-sm">
                    <label>Stato</label>
                    <div class="input-group input-group-sm" >
                        <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="record.status">
                            <option value="0">Non pubblicato</option>
                            <option value="1">Pubblicato</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group form-group-sm">
                    <label>Tabella</label>
                    <app-selecttables [name]="'table'" [value]="record.table" [show_all]="false" (onchange)="record.table=$event;"></app-selecttables>
                </div>
            </div>
            <div class="col-lg-12 mt-3">
                <div class="form-group">
                    <h5>Condizioni</h5>
                    <small>Indicare le condizioni che si devono verificare per applicare la regola</small>
                    <table class="table table-sm mb-0">
                        <thead>
                            <th>Campo</th>
                            <th>Condizione</th>
                            <th>Valore</th>
                            <th></th>
                            <th><button type="button" class="btn btn-xs btn-success float-right" (click)="addCondition()"><i class="fa fa-plus"></i></button></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of record.conditionsArray">
                                <td><input type="text" class="controls" [(ngModel)]="c.field"/></td>
                                <td><select class="controls" [(ngModel)]="c.condition">
                                        <option value="ugual">=</option>
                                        <option value="minor">minore</option>
                                        <option value="major">maggiore</option>
                                    </select> 
                                </td>
                                <td><input type="text" class="controls" [(ngModel)]="c.value"/></td>
                                <td><button type="button" class="btn btn-sm btn-danger" (click)="removeCondition(c)"><i class="fa fa-trash"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div class="col-12 mt-2">
                <ul class=" nav nav-tabs auto " *ngIf="record.gateways">
                    <ng-container *ngFor="let g of record.gateways;let i=index">
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="tab==i" (click)="tab=i">
                                <span *ngIf="g.gateway=='email'">Email</span>
                                <span *ngIf="g.gateway=='sms'">SMS</span>
                                <span *ngIf="g.gateway=='whatsapp'">WhatsApp</span>
                                <span *ngIf="g.gateway=='app'">Push (App)</span>
                            </a>
                        </li>
                    </ng-container>
                    <!--
                    <li class="nav-item"><a class="nav-link" [class.active]="tab==1" (click)="tab=1">Email</a></li>
                    <li class="nav-item" *ngIf="module.getParam('sms_enable',false)"><a class="nav-link" [class.active]="tab==2" (click)="tab=2">SMS</a></li>
                    <li class="nav-item" *ngIf="module.getParam('whatsapp_enable',false)"><a class="nav-link" [class.active]="tab==3" (click)="tab=3">Whatsapp</a></li>
                    <li class="nav-item" *ngIf="module.getParam('app_enable',false)"><a class="nav-link" [class.active]="tab==4" (click)="tab=4">Push (app)</a></li>
            -->
                </ul>
            </div>
            <div class="tab-content" id="myTab2Content" *ngIf="record.gateways">
                <ng-container *ngFor="let g of record.gateways;let i=index">
                    <div class="tab-pane p-4 fade show active" *ngIf="tab==i" >
                        <app-gateway  [gateway]="g"  [isConfig]="true" ></app-gateway>
                    </div>
                </ng-container>
                
                
                <!--
                <div class="tab-pane p-4 fade show active" *ngIf="tab==1">
                    <app-gateway *ngIf="gateway_email!=undefined" [gateway]="gateway_email" (OnFieldsChanged)="gateway_email=$event" [isConfig]="true" ></app-gateway>
                </div>
                <div class="tab-pane p-4 fade show active" *ngIf="module.getParam('sms_enable',false) && tab==2">
                    <app-gateway *ngIf="gateway_sms!=undefined" [gateway]="gateway_sms" (OnFieldsChanged)="gateway_sms=$event" [isConfig]="true"></app-gateway>
                </div>
                <div class="tab-pane p-4 fade show active" *ngIf="module.getParam('whatsapp_enable',false) && tab==3">
                    <app-gateway *ngIf="gateway_whatsapp!=undefined" [gateway]="gateway_whatsapp" (OnFieldsChanged)="gateway_whatsapp=$event" [isConfig]="true"></app-gateway>
                </div>
                <div class="tab-pane p-4 fade show active" *ngIf="module.getParam('app_enable',false) && tab==4">
                    <app-gateway *ngIf="gateway_app!=undefined" [gateway]="gateway_app" (OnFieldsChanged)="gateway_app=$event" [isConfig]="true"></app-gateway>
                </div>
            -->
            </div>
        </div>
    </form>
    <app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>

</app-window>