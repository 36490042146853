import { Component, OnInit,Input } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-windowlist',
  templateUrl: './windowlist.component.html',
  styleUrls: ['./windowlist.component.css']
})
export class WindowlistComponent implements OnInit {

  @Input()
  model:any=null;

  @Input()
  showCard=true;
  @Input()
  title:string="";
  constructor() { }

  ngOnInit(): void {
    
  }

  close(){

    this.model.modalWindow.close();
  }

  confirmSelections(){
    this.model.modalWindow.close("success");
  }

}
