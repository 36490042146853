<app-modal title="Seleziona il modello" [showFooter]="false" >
    <div class="m-4 text-center" *ngIf="templates.length==0">
        <i class="fa-2x fas fa-exclamation-triangle text-warning"></i><br>
        <i>Nessun modello di esportazione trovato!</i>
    </div>
    <div *ngFor="let n of templates" >
        <button type="button" class="btn btn-default btn-block btn-light text-left mb-1" (click)="getTemplate(n)">
            <div class="float-left mr-3">
                <i  title="{{n.format}} file" class="fa-2x fas fa-file fa-file-{{n.format}}" 
                    [class.fa-file-code]="n.format=='htm' || n.format=='html' || n.format=='xml'"
                    [class.text-teal]="n.format=='csv'"
                    [class.text-maroon]="n.format=='pdf'">
                    </i>
                    <br><small class="text-gray text-uppercase ">{{n.format}}</small>
            </div>
            <div class="float-left" style="width:85%;line-height:90%;white-space:break-spaces;">
                <b>{{n.name}}</b><br>
                <small>{{n.description}}</small>
            </div>
            <div class="float-right">
                <i *ngIf="n.detail==1"  title="richiede la selezione degli elementi" class="fas fa-tasks text-gray"></i><br>
                <i *ngIf="n.inrow==1"  title="presente nella riga dell'elemento" class="fas fa-star text-gray"></i>
            </div>
        </button>
    </div>
</app-modal>


<ng-template #modalRequestParams>
    <div class="modal-header bg-light">
        <span><i class="far fa-edit mr-2"></i>Parametri</span>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeRequestParams()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div *ngFor="let rp of selectedTemplate.requestparamsArray">
                <label>{{rp.label}}</label>
                <ng-container *ngIf="rp.type=='text'">
                    <input type="text" class="form-control form-control-sm"  name="{{rp.name}}" [(ngModel)]="rp.value">
                </ng-container>
                <ng-container *ngIf="rp.type=='select'">
                    <select class="form-control form-control-sm"  name="{{rp.name}}" [(ngModel)]="rp.value">
                        <option *ngFor="let opt of rp.default.split(';')">{{opt}}</option>
                    </select>
                </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmRequestParams()">Conferma</button>
        <button class="btn btn-secondary" (click)="closeRequestParams()">Annulla</button>
    </div>
</ng-template>