<div class="row" *ngIf="isConfig">
    <div class="col-12" >
        <div class="form-group form-group-sm mb-1">
            <b>Abilita</b>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm" name="enabled" [(ngModel)]="gateway.enabled" >
                    <option [ngValue]="0">No</option>
                    <option [ngValue]="1">Si</option>
                </select>
            </div>
        </div>
        <div class="form-group form-group-sm mb-1" *ngIf="gateway.gateway!='app'">
            <label>Tipologia di contatto</label>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm"  #type_contact [(ngModel)]="gateway.id_type"  title="{{gateway.id_type}}" >
                    <option *ngFor="let t of type_contacts" value="{{t.id}}" >{{t.name}}</option>
                    <option value="0">Contatto specifico</option>
                </select>

                <div class="input-group-append" *ngIf="gateway.id_type==0">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="email_to" (keyup)="ParseFields()">
                </div>
            </div>


        </div>
    </div>
</div>
<div class="row" *ngIf="!isConfig">
    <div class="col-2" >
        <div class="form-group form-group-sm mb-1">
            <b>Abilita</b>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm" name="enable" [(ngModel)]="gateway['enable']" >
                    <option [ngValue]="false">No</option>
                    <option [ngValue]="true">Si</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-10" *ngIf="gateway.gateway!='app'">
        <div class="form-group form-group-sm mb-1">
            <b>Contatto</b>
            <div class="input-group input-group-sm" >
                <select class="custom-select custom-select-sm" name="contact" [(ngModel)]="gateway['contact']" (change)="onSelectContact($event)">
                    <option *ngFor="let c of gateway['contacts']" [ngValue]="c.value" >{{c.value}} <span *ngIf="c.note!=''">({{c.note}})</span></option>
                    <option value="">Altro...</option>
                </select>
                <input type="text" placeholder="inserisci qui il contatto" class="form-control  form-control-smml-2" name="contact" [(ngModel)]="gateway['contact']" *ngIf="other_contact" />
                <input type="text" placeholder="inserisci il nome di contatto" class="form-control form-control-sm" name="contact_name" [(ngModel)]="gateway['contact_name']" *ngIf="other_contact" />
            </div>
        </div>
    </div>
</div>
<!-- EMAIL -->
<div class="row" *ngIf="gateway.gateway=='email'">
    <div class="col-sm-6">
        <div class="form-group form-group-sm mb-1">
            <b>Da</b>
            <input type="email" class="form-control form-control-sm" placeholder="..." name="email_from" [(ngModel)]="email_from" (keyup)="ParseFields()" >
        </div>
    </div>
    <div class="col-sm-6">
        <div class="form-group form-group-sm mb-1">
            <b>CC</b>
            <input type="email" class="form-control form-control-sm" placeholder="..." name="email_cc" [(ngModel)]="email_cc" (keyup)="ParseFields()">

        </div>
    </div>
    <div class="col-sm-12">
        <div class="form-group form-group-sm mb-4">
            <b>Oggetto</b>
            <input type="email" class=" form-control form-control-sm" placeholder="..." [(ngModel)]="email_subject" (keyup)="ParseFields()">
        </div>
    </div>
    <div class="col-sm-12">
        <div class="row">
            <div class="col-md-6">
                <label>HTML</label>
                <textarea class="form-control" rows="10" cols="300" name="email_body" [(ngModel)]="email_body" (keyup)="ParseFields()"></textarea>
            </div>
            <div class="col-md-6">
                <label>Anteprima</label>
                <div class="border" [innerHTML]="email_body"></div>
            </div>
            
        </div>
        
    </div>
    <div class="col-sm-12 mt-2">
        <div class="form-group form-group-sm">
            <b>Allegati</b>
            <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="url del file da allegare" [(ngModel)]="email_attachment" (keyup)="ParseFields()">
                <div class="input-group-append">
                    <button class="btn btn-default" (click)="downloadAttach(email_attachment)">Vedi</button>
                </div>    
            </div>
            <!-- <small>
                usare le keywords per specificare un collegamento dinamico. <br/>
                ex template[18,id] 18 = id template di esportazione
            </small> -->
        </div>
    </div>
     <!--<ckeditor [editor]="Editor" [(ngModel)]="email_body" (keyup)="ParseFields()"></ckeditor>-->
</div>
<!-- SMS -->
<div class="row" *ngIf="gateway.gateway=='sms'">
    <div class="col-sm-12">
        <div class="form-group form-group-sm mb-1">
            <b>Messaggio</b>
            <textarea rows="10" cols="300" class="form-control form-control-sm" placeholder="..." name="sms_body" [(ngModel)]="sms_body" (keyup)="ParseFields()" ></textarea>
        </div>
    </div>
</div>
<!-- WhatsApp-->
<div class="row" *ngIf="gateway.gateway=='whatsapp'">
    <div class="col-sm-12">
        <div class="form-group  form-group-sm mb-1">
            <b>Messaggio</b>
            <textarea rows="10" cols="300" class="form-control form-control-sm" placeholder="..." name="whatsapp_body" [(ngModel)]="whatsapp_body" (keyup)="ParseFields()" ></textarea>
        </div>
    </div>
</div>
<!-- App-->

<div class="row" *ngIf="gateway.gateway=='app'">
    <div class="col-sm-12">
        <div class="form-group  form-group-sm mb-1">
            <b>Messaggio</b>
            <textarea rows="10" cols="300" class="form-control form-control-sm" placeholder="..." name="app_body" [(ngModel)]="app_body" (keyup)="ParseFields()" ></textarea>
        </div>
    </div>
</div>


