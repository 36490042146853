import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormBuilder,FormGroup, FormArray, FormControl } from '@angular/forms';
import { TypesService } from '../../services/types.service';
import { Type } from '../../db/type';
import { Contact } from '../../db/contact';


import { ActivitiesService } from '../../services/activities.service';
import { Activities } from '../../db/activities';
import {DeadlinerulesService} from '../../services/deadlinerules.service'
import {NotificationService} from '../../../../../core/src/lib/notification.service';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, Ordering, OrderingMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { ActivitiesModule } from './activities.module';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { ActivitiesDetailsComponent } from './activities-details/activities-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeadlineDetailsComponent } from '../deadlines/deadline-details/deadline-details.component';
import { ContactsService } from '../../services/contacts.service';
import { AddressesService } from '../../services/addresses.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.sass']
})
export class ActivitiesComponent extends ModelList<Activities> implements OnInit {
  tab=1;
  @ViewChild("rescheduleModal")
  rescheduleModal:TemplateRef<any>
  rescheduleModalRef;
  rescheduleAction;
  rescheduleRecord;

  @Input()
  id_address:number;

  @Input()
  table:string;

  @Input()
  id_table:number;

  @Input()
  reference:any;

  @Input()
  filter_box:boolean=true;
  
  @Input()
  no_dateend:boolean=false;

  @Input()
  id_contract:number=0;

  @Input()
  default_filterdate=(this.mode!='embedded')?1:100;

  
  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;

  type_activities:Type[];
  status_activities:Type[];
  users:User[];
  total_amount=0;
  total_paid=0;

  filter_search:Filter=new Filter();
  filter_search_city:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_phase:Filter=new Filter();

  filter_id_address:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_status_address:Filter=new Filter();
  filter_contract:Filter=new Filter();

  isFilterSet:boolean=false;

  constructor(
    private fb1: UntypedFormBuilder,
    private activitiesService: ActivitiesService,
    private typesService: TypesService,
    private userService:UserService,
    private notificationService:NotificationService,
    private modalService:NgbModal,
    private addressesService:AddressesService
    
  ) {
    super(activitiesService,fb1);
    this.detailViewModal=ActivitiesDetailsComponent

    


   }

  ngOnInit(): void {
    this.title = 'Attività';
    super.ngOnInit.apply(this, arguments);
    
    this.paging_count = 25;
    
    this.typesService.getTypes("activities").subscribe((items)=>{
      this.type_activities=items;
    });

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });
    //TODO da cancellare
    /*
    ActivitiesModule.savingActivityEvent.subscribe(()=>{
      this.updateList();
    });
    */
   if(Globals.events['savingActivityEvent']){
    Globals.events['savingActivityEvent'].subscribe(()=>{
      this.updateList();
    });
  }
    

    this.afterGetItems=()=>{


      this.activitiesService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
        this.total_amount=item.total_amount;
        this.total_paid=item.total_paid;
      });

      for(let r of this.list)
        try{
          r.missingValues=JSON.parse(r.missingValues.toString());
        }catch{
          r.missingValues=[];
        }
    }

    //if(this.mode!="embedded")
      //this.updateList();

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("a.date");
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to); 
    this.filter.push(this.filter_date);
  }

  setFilters():void{


    

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("a.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);



    /*
    this.date_from=this.today;
    this.date_to.setDate(this.today.getDate()+30);
    */

    

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("a.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("a.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);

    
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("a.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("a.table");
    this.filter_table.value="";
    this.filter.push(this.filter_table);

    this.filter_id_table.mode=FilterMode.normal;
    this.filter_id_table.fields=[];
    this.filter_id_table.fields.push("a.id_table");
    this.filter_id_table.value="";
    this.filter.push(this.filter_id_table);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("addr.name");
    this.filter_search.fields.push("a.description");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_search_city.mode=FilterMode.like;
    this.filter_search_city.fields=[];
    this.filter_search_city.fields.push("addr.city");
    this.filter_search_city.fields.push("s.city");
    this.filter_search_city.value="";
    this.filter.push(this.filter_search_city);

    
/*
    this.filter_status_address.mode=FilterMode.normal;
    this.filter_status_address.fields=[];
    this.filter_status_address.fields.push("addr.status");
    this.filter_status_address.value="1";
    this.filter.push(this.filter_status_address);

*/
    this.filter_phase.mode=FilterMode.normal;
    this.filter_phase.fields=[];
    this.filter_phase.fields.push("a.phase");
    this.filter_phase.value="";
    this.filter.push(this.filter_phase);

    if(this.id_contract>0){

      this.filter_contract.mode=FilterMode.custom;
      this.filter_contract.fields=[];
      this.filter_contract.value="(a.id_table="+this.id_contract.toString()+" AND a.table='contracts') OR (a.id_table IN (SELECT id FROM contractsinstallments WHERE id_contract="+this.id_contract.toString()+") AND a.table='contractsinstallments')";
      this.filter.push(this.filter_contract);
    }


    this.ordering.mode=OrderingMode.discendent;  
    this.ordering.field="a.date";
    
    let ordering_priority:Ordering={} as Ordering;
    ordering_priority.mode=OrderingMode.discendent;
    ordering_priority.field="a.priority";
    this.orderingList.push(ordering_priority);

    
    if(this.mode=="")
      this.advancedFilter=Globals.navigation.getStateFilters(this.constructor.name,this.filter);

    

    this.isFilterSet=true;
  }
  ngOnChanges():void{
    if(this.id_table>0  || this.id_contract>0 || this.id_address>0)
      this.updateList();
  }

  updateList(data_type=0):void{

    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
      if((this.id_table==0 || this.id_table==undefined) && (this.id_address==0 || this.id_address==undefined))
     
        return;

      
    }
    
    if(!this.isFilterSet)
      this.setFilters();

    if(this.id_table>0 && this.table!=""){
      this.filter_table.value=this.table;
      this.filter_id_table.value=this.id_table.toString();
      
    }

    if(this.id_address>0){
      this.filter_id_address.value=this.id_address.toString();
    }

    if(data_type==11 || this.mode=="embedded"){
      this.ordering.mode=OrderingMode.ascendent;  
      this.ordering.field="a.date";
    }

    this.getItems();
  }

  add():void{
    
      this.openDetail();
      
  }


  notify(record:Activities){
    let id_address:number;    
    
    this.addressesService.getItem(record.id_address).subscribe((result)=>{
      let contacts:Contact[]=result.contacts;
      this.notificationService.showPreviewNotification(contacts,record,"activities",()=>{
      });

    })
    

    /*
    switch(record.table){
      case "products":
        contacts=record.reference['shop']['addressItem']['contacts'];
        id_address=record.reference['shop']['addressItem']['id'];
        break;
      case "installations":
        contacts=record.reference['shop']['addressItem']['contacts'];
        id_address=record.reference['shop']['addressItem']['id'];
        break;
      case "shops":
        contacts=record.reference['addressItem']['contacts'];
        id_address=record.reference['addressItem']['id'];
        break;
      case "contracts":
        contacts=record.reference['shop']['addressItem']['contacts'];
        id_address=record.reference['shop']['addressItem']['id'];
        break;
      case "addresses":
          contacts=record.reference['contacts'];
          id_address=record.reference['id'];
          break;
          
  
      
    }
    */

    

    
    
  }


  confirmActivities(record:Activities){
    record.phase=3;
    this.activitiesService.save(record,(id)=>{
      let deadlinerulesService:DeadlinerulesService=new DeadlinerulesService();
      deadlinerulesService.getRule("activities",record,()=>{});
    });
  }

  reschedule(record:Activities){
    this.rescheduleRecord=record;
    this.rescheduleModalRef=this.modalService.open(this.rescheduleModal);
  }

  confirmReschedule(){
    if(this.rescheduleAction==1){ //crea agenda
      Globals.modal.showModal(DeadlineDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"date","value":this.rescheduleRecord.reschedule},
        {"name":"id_address","value":this.rescheduleRecord.id_address},
        {"name":"reference","value":this.rescheduleRecord.reference},
        {"name":"id_table","value":this.rescheduleRecord.id_table},
        {"name":"table","value":this.rescheduleRecord.table}
        
      ],(instance)=>{
          if(instance!=null){
            this.getItems();
          }
        });
    }
    if(this.rescheduleAction==2){ //modifica attività
      this.openDetail(this.rescheduleRecord,{"name":"new_date","value":this.rescheduleRecord.reschedule});
    }

    this.closeReschedule();
  }

  closeReschedule(){
    this.rescheduleModalRef.close("success");
  }

 

}
