import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Form, FormFieldValues, FormValue } from 'projects/core/src/include/structures';
import { FormsService } from 'projects/core/src/lib/forms.service';
import { FormsValueService } from '../../services/formsvalue.service';

import { UploadService } from 'projects/core/src/lib/upload.service';
import { Helper } from 'projects/core/src/helper';
import { NotificationService } from '../../../../../core/src/lib/notification.service';

@Component({
  selector: 'app-newform',
  templateUrl: './newform.component.html',
  styleUrls: ['./newform.component.css']
})
export class NewformComponent implements OnInit {
  @ViewChild("sigPad")
  sigPad:SignaturePadComponent;

  @ViewChild("modalSignature")
  modalSignature:TemplateRef<any>;
  modalSignatureRef;

  @Input()
  table:string;
  
  @Input()
  reference:any;

  @Input()
  id_form=0;

  @Input()
  id_formvalue=0;

  @Input()
  enableSignature=true;

  @Input()
  enableNotification=true;

  @Input()
  readonly=false;

  forms:Form[]=[];
  form:Form;
  step=0;

  id_section=0;
  title:string="";

  record:FormValue=new FormValue();

  idx_sections=0;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': window.innerWidth,
    'canvasHeight': window.innerHeight-200
  };


  signature;

  constructor(
    private formsService:FormsService,
    private formsValuesService:FormsValueService,
    private modalService:NgbModal,
    private uploadService:UploadService,
    private notificationService:NotificationService
  ) { }

  ngOnInit(): void {
    this.formsService.getForms(this.table).subscribe((items)=>{
      this.forms=items;
      if(this.forms.length>0){
        this.record.id_form=this.forms[0].id;
        this.record.id_notification=this.forms[0].id_notification;
        this.record.id_template=this.forms[0].id_template;
        
      }

      if(this.id_form>0){
        this.step=0;
        for(let i=0;i<this.forms.length;i++){
          if(this.forms[i].id==this.id_form){
            this.record.id_form=this.forms[i].id;
            this.record.id_notification=this.forms[i].id_notification;
            this.record.id_template=this.forms[i].id_template;
            this.formSelected();
          }
        }
      }else{
        this.title="Nuovo form";
        this.step=1;
      }

    });

    this.record.id_table=this.reference['id'];
    this.record.table=this.table;
    this.record.id_user=Globals.user.id;


  }

  formSelected(){
   
    this.formsService.getForm(this.record.id_form,this.reference,(item)=>{
      
      //let form=new FormValue();
      //Object.assign(form,item);
      //this.record=form;
      
      
      for(let f of item.fields){
          let fv=new FormFieldValues();
          Object.assign(fv,f);
          fv.id_field=f.id;
          
          fv.id=0;
          
          this.record.fields.push(fv);
      }
      this.record.sections=item.sections;
     
      this.record.id=this.id_formvalue;
      this.id_section=this.getFirstIdSection();
      this.step=2;
      this.title=item.name;
    },this.id_formvalue);
    
    
    

  }

  formConfirm(){
    if(this.enableSignature){
      this.step=3;
      this.openSignature();
    }else{
      this.formSave();
    }
  }

  formSave(){
    if(this.readonly){
      this.close();
      return;
    }
   
    if(this.getNotCompiledRequiredFields()>0){
      if(!confirm("Non tutti i campi obbligatori sono stati compilati. Continuare lo stesso?")){
        return;
      }
    }


    this.formsValuesService.save(this.record,(id)=>{
      this.record.id=id;
      if(this.enableNotification){
        if(confirm("Inviare una notifica all'utente?")){
          this.notificationService.showPreviewNotification(Helper.getAddressItem(this.table,this.reference).contacts,this.record,"formsvalue",()=>{
  
          },0,this.record.id_notification);
          
        }
      }
      
      
      
      this.close();
    });
  }
  close(confirmMessage=false){

    if(confirmMessage && !this.readonly){
      if(confirm("Salvare le modifiche prima di chiudere?")){
        this.formConfirm();
      }
    }

    this['modalWindow'].close('success');

    
  }

  openSignature(){
    this.modalSignatureRef=this.modalService.open(this.modalSignature,{size:"xl"});

  }

  confirmSignature(sigPad){
    let signature=sigPad.toDataURL().replace("data:image/png;base64,","");
    this.uploadService.uploadImage64(signature,"signatures",(result)=>{
      this.record.signature=result;
    },true);

    this.modalSignatureRef.close();
  }

  getSignatureImage(){
    return Globals.config.serverUrl+"/"+this.record.signature;
  }


  /** Conta quanti (obbligatori) sono compilati per sezione */
  getCompiledField(id_section=0){
    let count=0;
    let sections=this.record.sections;
    for(let s of sections){
      if(s.id==id_section || id_section==0){
        for(let f of s.fields){

          if(f.condition_enable_fieldname!=''){
            if(this.checkEnableCondition(s.fields,f.condition_enable_fieldname)){
              
              if((f.value=="" || f.value==undefined || f.value==null) && f.required && f.access==1){
                count++;
              }
            }
          }else{
            if(f.value=="" && f.required && f.access==1){
              count++;
            }
          }

          
        }
      }
    }

    return count;
  }


  /** Conta quanti campi obbligatori non sono stati compilati (in totale) */
  getNotCompiledRequiredFields(){
    let count=0;
    let sections=this.record.sections;
    for(let s of sections){
      for(let f of s.fields){
        if(f.condition_enable_fieldname!=''){
          if(this.checkEnableCondition(s.fields,f.condition_enable_fieldname)){
            
            if((f.value=="" || f.value==undefined || f.value==null) && f.required && f.access==1){
              count++;
            }
          }
        }else{
          if((f.value=="" || f.value==undefined || f.value==null) && f.required && f.access==1){
            count++;
          }
        }
        
      }
    }

    return count;
  }

  getFirstIdSection(){
    let sections=this.record.sections;
    if(sections.length>0){
      return sections[0].id;
    }

    return 0;
  }

  nextSection(){
    for(let i=0;i<this.record.sections.length;i++){
      if(this.id_section==this.record.sections[i].id){
        if((i+1)<=this.record.sections.length){
          this.id_section=this.record.sections[i+1].id;
          return;
        }
      }
    }
  }

  prevSection(){
    for(let i=0;i<this.record.sections.length;i++){
      if(this.id_section==this.record.sections[i].id){
        if((i-1)>-1){
          this.id_section=this.record.sections[i-1].id;
          return;
        }
      }
    }
  }


  checkEnableCondition(fields,enable_condition_fieldname){
    for(let f of fields){
      if(f.name==enable_condition_fieldname){
        if(f.value!='' && f.value!=0){
          
          return true;
        }

          
      }
    }

    return false;
  }
}
