import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationlogsComponent } from './notificationlogs/notificationlogs.component';
import { FormsModule } from '@angular/forms';
import { PreviewnotificationComponent } from './previewnotification/previewnotification.component';
import { ModalModule } from '../modal/modal.module';
import { Globals } from '../../globals';
import { GatewayComponent } from './gateway/gateway.component';
import { SettingNotificationComponent } from './settings/list/notification.component';
import { WindowlistModule } from '../windowlist/windowlist.module';
import { SettingNotificationDetailComponent } from './settings/detail/detail.component';
import { WindowModule } from '../window/window.module';
import { ToolbarmodelModule } from '../toolbarmodel/toolbarmodel.module';
import { SelectTablesModule } from '../tables/tables.module';



@NgModule({
  declarations: [NotificationlogsComponent,PreviewnotificationComponent,GatewayComponent,SettingNotificationComponent,SettingNotificationDetailComponent],
  exports:[NotificationlogsComponent,PreviewnotificationComponent,GatewayComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    WindowlistModule,
    WindowModule,
    ToolbarmodelModule,
    SelectTablesModule
  ]
})
export class NotificationsModule { 

  constructor(){


    Globals.parameters.loadModulesEvent.subscribe((result)=>{
      Globals.parameters.createParam(
          "notifications",
          "sms_c1connector",
          "Abilita invio sms da C1 Connector",
          "boolean"
      );

      Globals.parameters.createParam(
        "notifications",
        "whatsapp_c1connector",
        "Abilita invio whatsapp da C1 Connector",
        "boolean"
      );

      Globals.parameters.createParam(
        "notifications",
        "app_enable",
        "Abilita la notifica push tramite App",
        "boolean"
      );

      Globals.parameters.createParam(
        "notifications",
        "sms_enable",
        "Abilita la notifica tramite SMS",
        "boolean"
      );

      Globals.parameters.createParam(
        "notifications",
        "whatsapp_enable",
        "Abilita la notifica tramite Whatsapp",
        "boolean"
      );
    });
  }
}
