import { Component, OnInit,Input ,Output,EventEmitter} from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-selecttables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.sass']
})
export class SelectTablesComponent implements OnInit {

  constructor() { }

  @Input()
  value:any;

  @Input()
  name:string;

  @Input()
  show_all:boolean=false;

  @Input()
  no_select:boolean=false;

  tables=[];

  @Output() onchange = new EventEmitter();


  ngOnInit(): void {

    for(let m of Globals.parameters.modules){
      if(m.table!="" && m.table!=null && m.table!=undefined){
        let tt=m.table.split(",");
        for(let tt2 of tt){
          let t={};
          t['table']=tt2;
          t['label']=m.label+(tt.length>1?" ("+tt2+")":"");
          this.tables.push(t);
     
        }
      }
    }
  }


  onChange(){
    this.onchange.emit(this.value);
  }


  checkTable(table){
    for(let t of this.tables){
      if(t['table']==table)    
          return t['label'];
      }
  }


}
