import { Type } from "./type"
import { Address } from "./address"
import {Product} from "./product";
import { DocumentsService } from "../services/documents.service";
import { Helper } from "projects/core/src/helper";
import { CustomField } from "projects/core/src/include/structures";
import { det } from "mathjs";
import { Globals } from "projects/core/src/globals";


export const DocumentsType={
    FATTURA_SEMPLICE:1,
    FATTURA_ACCOMPAGNATORIA:2,
    NOTA_DI_CREDITO:3,
    PREVENTIVO:4,
    DDT:5,
    SCONTRINO:6,
    ORDINE_CLIENTE:7,
    ORDINE_FORNITORE:8,
    NON_FISCALE:9,
    TRASFERIMENTO:10,
    AMMINISTRATIVO:11,
    FATTURA_ACCONTO:12

}

export const DocumentMode={
    sell:1,
    buy:2
}

export class Document{
    id_document:number;
    name:string;
    type:string;
    date:string;
    doc:string;
    reference:string;
    
  }
  
export class DocumentDetail{
    id:number;
    code:string;
    description:string;
    quantity:number;
    unit:string;
    netprice:number;
    tax:number;
    id_tax:number;
    discount1:number;
    discount2:number;
    discount3:number;
    percentual:number;
    gross:number;
    note:string;
    weight:number;
    id_product:number;
    product:Product;
    sn:string;
    quantityPartial:number;
    id_inventory_source:number;
    state_reference:any;
    iswithholdingtax:number;
    iswelfarefund:number;
    stock:number;
    stockinventories:any;
    otherdatacode:string;
    otherdatadescription:string;
    otherdatanumber:string;
    otherdatadate:any;
    
   

    constructor(){
        this.quantity=1;
        this.tax=0;
        this.discount1=0;
        this.discount2=0;
        this.discount3=0;
        
        
    }

    get total_net(){
        return this.quantity*this.netprice*(1-this.discount1/100)*(1-this.discount2/100)*(1-this.discount3/100);
    }


    get total_gross(){
        return this.total_net*(1+this.tax/100);
    }

}

export class Documents {
    id:number;
    code:string;
    date:string;
    id_type: number;
    typedocument:number;
    description:string;
    description2:string;
    amount:number;
    id_address:number;
    addressItem:Address;
    note:string;
    reference:string;
    referenceprefix:string;
    status:number;
    customfields:CustomField[];
    details:DocumentDetail[];
    name:string;
    address:string;
    city:string;
    country:string;
    zip:string;
    nation:string;
    vat_number:string;
    tax_code:string;
    sdi:string;
    pec:string;
    name_destination:string;
    address_destination:string;
    city_destination:string;
    country_destination:string;
    zip_destination:string;
    aspect:string;
    vector:string;
    track_number:string;
    transport:string;
    port:string;
    transport_date:string;
    transport_note:string;
    weight:number;
    packages:number;
    methodpayment:string;
    bank:string;
    iban:string;
    documentlinked:any;
    islinked:boolean;
    paymentschedule:Date;
    shipping_cost:number;
    packaging_cost:number;
    discount:number;
    net_total:number;
    tax_total:number;
    discount_total:number;
    total:number;
    total_pay:number;
    state:number;
    history:any[];
    private:number;
    id_document_linked:number;
    RT:string;
    id_documentsrule;
    documentsrule:any;
    id_table:number;
    table:string;
    sdi_status:number;
    sdi_idinvoice:number;
    sdi_message:string;
    created_from:number;
    paid:number;
    cashflow:any[];
    id_user:number;
    referencedocument:any;
    enable_inventory:any;
    origin:string;
    id_shop_start:number;
    id_shop_end:number;
    shop_start:any;
    shop_end:any;
    files:DocumentFile[];
    ordernumber:string;
    orderdate:string;
    cup:string;
    cig:string;
    managementvalues:any;
    id_financialaccount:number;
    financialaccount:string;
    id_inventory:number;
    forceCashflow:boolean=false;
    tags:any;
    note2:string;
    date_shop_start:string;
    date_shop_end:string;
    tax:number;
    remain:number;
    withholdingtaxenabled:boolean=false;
    welfarefundenabled:boolean=false;
    set documentsRule(value){
        this.documentsrule=value;
        this.id_documentsrule=value['id'];
    }

    get documentsRule(){
        return this.documentsrule;
    }


    addDetail(detail:DocumentDetail,groupProducts=false){
         
        if(!this.details)
            this.details=[];

        //verifica se il bene è stato già inserito
        let is_good_added=false;

        if(groupProducts){
            for(let d of this.details){
                if(d.id_product==detail.id_product){
                    d.quantity=d.quantity+detail.quantity;
                    is_good_added=true;
                }
            }
        }

        if(!is_good_added){
            this.details.push(detail);
        }

    }

    calculateTotals(){
        this.net_total=0;
        this.total=0;
        this.discount_total=0;
        this.tax_total=0;
        
        for(let d of this.details){

            let net=d.quantity*d.netprice*(1-d.discount1)*(1-d.discount2/100)*(1-d.discount3/100);

            this.net_total=this.net_total+net;
            this.tax_total=  this.tax_total+net*(d.tax/100);
            this.discount_total=this.discount_total+d.quantity*d.netprice*(d.discount1)*(d.discount2/100)*(d.discount3/100)
        }

        this.total=this.net_total+this.tax_total;
        
    }


    constructor(
        referenceprefix:string="",
        typedocument:number=1,
        id_type:number=1,
        
    ){
        this.id=0;
        this.referenceprefix=referenceprefix;
        this.typedocument=typedocument;
        this.date=Helper.convertDateControl();
        this.id_type=id_type;
        this.customfields=[];
        this.details=[];
        this.documentlinked=[];
        this.shipping_cost=0;
        this.packaging_cost=0;
        this.weight=0;
        this.vector="";
        this.packages=0;
        this.discount=0;
        this.enable_inventory=false;
        let dp:Date=new Date();
        this.paymentschedule=Helper.convertDateControl(dp);
        this.sdi="0000000";
        this.files=[];
        this.paid=0;
        this.date_shop_start=Helper.convertDateControl(new Date(),true);
        this.date_shop_end=Helper.convertDateControl(new Date(),true);
        


        //this.getNextNumeration();
        //this.addressItem=new Address();
    }

    getNextNumeration(){
        const documentsService: DocumentsService=new DocumentsService();
        documentsService.getNextNumeration(this.date,this.id_type,this.typedocument,this.referenceprefix).subscribe((item)=>{
            this.reference=item.toString();
        });
    }
    
}

export class DocumentFile{
    id:number;
    id_document:number;
    name:string;
    file:string;
    edit:boolean;
}