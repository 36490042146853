<section class="content">
    <div class="row my-1">
        <div class="col-lg-5 text-right" >
            <label>Anno</label>
        </div>
        <div class="col-lg-2" >          
            <select class="form-control form-control-sm" [(ngModel)]="year" (ngModelChange)="getItems()">
                <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
                <canvas baseChart style="max-height:70vh !important" 
                            [legend]="false"
                            [datasets]="chartData"
                            [labels]="chartLabels"
                            [options]="chartOptions"
                    >
                </canvas>
                <table class="table">
                    <thead>
                        <th>Mese</th>
                        <th class="text-right">Entrate</th>
                        <th class="text-right">Uscite</th>
                        <th class="text-right">Differenza</th>                        
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of list">
                            <td>{{months[r.month-1]}}</td>
                            <td class="text-right">{{r.total_in | currency:"EUR"}}</td>
                            <td class="text-right">{{r.total_out | currency:"EUR"}}</td>
                            <td class="text-right" [class.text-danger]="(r.total_in - r.total_out)<0" [class.text-success]="(r.total_in - r.total_out)>0">{{r.total_in - r.total_out | currency:"EUR"}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td><b>TOTALE</b></td>
                            <td class="text-right"><b>{{total_in | currency:"EUR"}}</b></td>
                            <td class="text-right"><b>{{total_out | currency:"EUR"}}</b></td>
                            <td class="text-right" [class.text-danger]="(total_in-total_out)<0" [class.text-success]="(total_in-total_out)>0"><b>{{total_in-total_out | currency:"EUR"}}</b></td>
                            
                        </tr>
                    </tfoot>
                </table>
            </div>
          </div>
        </div>
    </div>
</section>