<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row mb-lg-0 mb-4">
            <div class="col-12">
                <div class="row">
                    <div class="col-6 col-lg-2">
                        <div class="form-group">
                            <label>Data<ng-container *ngIf="checkAfterToday(record.date)"> futura</ng-container></label>
                            <div class="input-group" >
                                <input [class.bg-warning]="checkAfterToday(record.date)" class="form-control form-control-sm" type="date" name="date" [(ngModel)]="record.date"  />
                                <div *ngIf="record.reference" class="input-group-append">
                                    <a class="btn btn-sm btn-default" (click)="record.date=record.reference.date" title="Usa data dell'elemento collegato"><i class="fas fa-paste"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2">
                        <div class="form-group">
                            <label>Tipologia</label><br>
                            <div class="btn-group btn-group-sm w-100">
                                <button [class.alert-success]="record.id_type==1" type="button" class="btn btn-default border btn-sm" (click)="record.id_type=1">Entrata</button>
                                <button [class.alert-danger]="record.id_type==2" type="button" class="btn btn-default border btn-sm" (click)="record.id_type=2">Uscita</button>
                            </div>
                            <!-- <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option value="1">Entrata</option>
                                <option value="2">Uscita</option>
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                                <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label>Causale</label>
                            <div class="input-group" >
                                <input type="text" class="form-control form-control-sm" placeholder="..." name="description" [(ngModel)]="record.description">
                                <div *ngIf="record.reference" class="input-group-append">
                                    <a class="btn btn-sm btn-default" (click)="descriptionFromReference()" title="Suggerisce dall'elemento collegato"><i class="fas fa-paste"></i></a>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label>Anagrafica</label>
                            <div class="small-box bg-light mb-0">
                                <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                    [showViewButton]="isModuleEnabled('address')"
                                    [showSearchButton]="isModuleEnabled('address')"
                                    [recordSelected]="record.addressItem"
                                    [service]="addressesService"
                                    [fieldRender]="'name'"
                                    [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                    <small>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <small>
                                                    <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                        <b>INDIRIZZO:</b><br>
                                                        {{record.addressItem.address}}<br>
                                                        {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                    </ng-container>
                                                    <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                    <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                </small>
                                            </div>
                                            <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                <small>
                                                    <b>CONTATTI:</b><br>
                                                    <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                </small>
                                            </div>
                                        </div>
                                    </small>
                                    <div class="icon"><i class="fa fa-user"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Metodo di pagamento</label>
                                    <select class="form-control form-control-sm" name="method_payment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.method_payment">
                                        <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group ">
                                    <label>Banca / Cassa</label>
                                    <div class="input-group input-group-sm">
                                        <select class="form-control form-control-sm" [(ngModel)]="record.source" name="source" #id_user="ngModel">
                                            <option *ngFor="let s of sources" [ngValue]="s.description">{{s.description}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group " *ngIf="isModuleEnabled('financialaccounts')">
                                    <label>Conti contabili</label>
                                    <app-financialaccount [id_financialaccount]="record.id_financialaccount" (selected)="record.id_financialaccount=$event['id'];"></app-financialaccount>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Note</label>
                                    <div class="input-group">
                                        <textarea  class="form-control" rows="5" name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                                    </div>
                                </div>
                            </div>                           
                            <div class="col-lg-8">
                                <div class="form-group ">
                                    <label>Operatore / Sede</label>
                                    <div class="input-group input-group-sm">
                                        <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                                            <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                                        </select>
                                        <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                                            <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                                        </div>
                                        <div class="input-group-append">
                                            <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                                <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>.</label><br>
                                    <a type="button" (click)="addDeadline(record)" class="btn btn-outline-success btn-block btn-sm"><i class="fas fa-plus-circle mr-2"></i>Crea prossima scadenza</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group mb-0">
                            <label>Importo</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" step="0.001" max="9999999999" name="amount" placeholder="..." 
                                oninput="validity.valid||(value='');" [(ngModel)]="record.amount" (change)="calculateGross()">
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <label>IVA</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">%</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" max="100" name="tax" 
                                oninput="validity.valid||(value='');"  [(ngModel)]="record.tax"  (change)="calculateGross()">
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <label>Totale <small>(Importo + IVA)</small></label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="gross" placeholder="..." 
                                oninput="validity.valid||(value='');" [(ngModel)]="record.gross" (change)="calculateNet()">
                            </div>
                        </div>
                        <div class="form-group mb-0" *ngIf="show_paid">
                            <label>Versato</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="paid" placeholder="..."
                                oninput="validity.valid||(value='');" [(ngModel)]="record.paid " >
                                <div class="input-group-append">
                                    <a type="button" class="btn btn-sm btn-default" title="Versato tutto" (click)="record.paid=record.gross"><i class="fas fa-equals"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group input-group-sm" *ngIf="show_paid">
                            <label>Differenza <small>(Totale - Versato)</small></label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <p class="form-control form-control-sm bg-light" [class.text-success]="record.paid - record.gross > 0" [class.text-danger]="record.paid - record.gross < 0">{{ record.paid - record.gross | currency:' ' }}</p> 
                            </div>
                        </div>
                        <div class="mt-2">
                            <label>Elemento collegato</label>
                            <div class="bg-light border p-1">
                                <p class="px-2 m-0">
                                    <small>
                                        <app-reference #referenceRecord [showConnectBtn]="true" [showDisconnect]="true" (recordConnected)="connectActivity($event)" [reference]="record.reference" [table]="record.table" [mode]="'detail'"></app-reference>
                                    </small>
                                </p>
                            </div> 
                        </div>
                    </div>
                </div>
                <!-- Extra fields -->
                <ng-container >
                    <hr>
                    <app-customfields [origin]="record" [list]="record.customfields" [table]="'cashflow'" [id_table]="record.id" [condition_id]="record.id_type" [condition_field]="record.method_payment" [access]="currentUser.role"></app-customfields>
                </ng-container>
            </div>
        </div>
    </form> 
    <app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>
</app-window>