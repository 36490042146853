import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "projects/core/src/globals";
import { Model } from "projects/core/src/include/model";
import { Origin, RequestParam, Template } from "projects/core/src/include/structures";
import { TemplatesService } from "projects/core/src/lib/templates.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SystemService } from "projects/core/src/lib/system.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Helper } from "projects/core/src/helper";

@Component({
    selector: 'setting-templates-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
  })
  export class TemplatesDetailComponent extends Model<Template> implements OnInit,AfterViewInit {
    @ViewChild("modalMethods")
    modalMethods:TemplateRef<any>;
    modalMethodsRef;
    
    @ViewChild("preview")
    iframePreview:ElementRef

    
    public Editor = ClassicEditor;
    editorOn=true;

    get user(){
        return Globals.user;
    }

    example:any='0';
    selectedOrigin:any;

    methods=[];
    sections=[];

    constructor(
        private route1: ActivatedRoute,
        private templatesService: TemplatesService,
        private systemService:SystemService,
        private modalService:NgbModal


    ){
        super(route1,templatesService); 
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.title = 'Modello di stampa';
    }

    ngAfterViewInit(): void {
        this.afterGetItem=()=>{
            
            this.record.originsArray=[];
            if(this.record.origins!="" && this.record.origins!=undefined)
              this.record.originsArray=JSON.parse(this.record.origins);

            this.record.requestparamsArray=[];
            if(this.record.requestparams!="" && this.record.requestparams!=undefined)
              this.record.requestparamsArray=JSON.parse(this.record.requestparams);
            
            this.getSections();

            if (this.record.id)
              this.updatePreview();
        }

        this.beforeSave=()=>{
            this.record.origins=JSON.stringify(this.record.originsArray);
            this.record.requestparams=JSON.stringify(this.record.requestparamsArray);
            return true;
            
        }


        this.getItem();
    }

    getSections(){
        this.sections=[];
        for(let s of Globals.sections){
          if(s.table==this.record.table){
            this.sections.push(s.name);
          }
        }
    }


  addOrigin():void{
    let c:Origin={} as Origin;
    if(this.record.originsArray==undefined)
      this.record.originsArray=[];

    this.record.originsArray.push(c);
  }

  removeOrigin(c:Origin):void{
    for(let i=0;i<this.record.originsArray.length;i++)
      if(this.record.originsArray[i]==c)
        this.record.originsArray.splice(i,1);
  }



  addRequestParam():void{
    let c:RequestParam={} as RequestParam;
    c['type']="text";
    if(this.record.requestparamsArray==undefined)
      this.record.requestparamsArray=[];

    this.record.requestparamsArray.push(c);
  }

  removeRequestParam(c:RequestParam):void{
    for(let i=0;i<this.record.requestparamsArray.length;i++)
      if(this.record.requestparamsArray[i]==c)
        this.record.requestparamsArray.splice(i,1);
  }

  getExample(c:Origin){
      let args=prompt("Inserire un ID dell'elemento per visualizzare i dati sorgente","1");
      if(args!=null){
        Globals.ws.requestUrl(c.table,c.task,[args]).subscribe((result)=>{
          this.example=result;
        });

      }
  }

  getPreview(){
    let args=prompt("Inserire un ID dell'elemento per ottenere l'anteprima","1");
      if(args){

        this.save(false,()=>{
          let m={};
          m['where']=args;
          Globals.setLoading(true);
          this.templatesService.openTemplate(this.record.id,JSON.stringify(m)).subscribe((url)=>{
            Globals.setLoading(false);
            window.open(url,"_blank");
          });
        });
        
      }

  }

  openModalMethods(table,origin){
    this.selectedOrigin=origin;
    this.systemService.getMethodsClass(table).subscribe((items)=>{
      this.methods=items;
      this.modalMethodsRef=this.modalService.open(this.modalMethods,{size:"xl"});
    })

    
  }
  
  selectMethod(method){
    this.selectedOrigin.task=method.name;
    this.closeModalMethods();
  }

  closeModalMethods(){
    this.modalMethodsRef.close("success");
  }

  transferToRemote(){
    this.record.source="remote";
    this.record.id=0;
    this.save();
  }

  transferToLocal(){
    this.record.source="local";
    this.record.id=0;
    this.save();
  }

  updatePreview(){
    let emptyPreview=Helper.replaceKeyword(this.record.body,{});


    this.iframePreview.nativeElement.contentWindow.frames.document.body.insertAdjacentHTML('beforebegin', emptyPreview);
  }

  }