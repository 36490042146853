<app-settingwindow [title]="'Documenti'" (onClose)="closeModal()" (onSave)="save()">
    <ul class="nav nav-tabs" id="myTab" role="tablist">        
        <li class="nav-item ">
            <a class="nav-link" [class.active]="tab=='2'" (click)="tab='2'" >Banche</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" [class.active]="tab=='3'" (click)="tab='3'" >Causali</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" [class.active]="tab=='4'" (click)="tab='4'" >Sezionali</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" [class.active]="tab=='4'" (click)="tab='5'" >Ritenuta e Contributi</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link" [class.active]="tab=='1'" (click)="tab='1'" >Plugins</a>
        </li>
    </ul>
    <div class="p-2" >
        <div class="tab-content" id="myTabContent">

            <div class="tab-pane fade show active" *ngIf="tab=='1'">
                <div class="row">
                    <div class="col-12">
                        <settingwindow-listplugins [type]="'documents'" [plugins]="plugins"></settingwindow-listplugins>                       
                    </div>
                    <!--
                    <div class="col-lg-8">
                        
                        <label>Codice fiscale del delegato</label>
                        <app-tax-code [showReverseBtn]="false"  [tax_code]="" (onTextChange)="true"></app-tax-code>
                        <label>Password</label>
                        <input type="password" class="form-control form-control-sm" name="" placeholder="..." >
                        <label>PIN</label>
                        <input type="password" max="8" class="form-control form-control-sm" maxlength="8" required name="" placeholder="..." >
                        <label>Password iniziale</label>
                        <input type="password" class="form-control form-control-sm" name="" placeholder="..." >
                        <button type="button" class="btn btn-outline-primary btn-block btn-sm mt-3 mb-2" (click)="true">Prova connessione</button>
                        <span *ngIf="false" class="text-danger">La prova di connessione è fallita. Controlla le credenziali di accesso e riprova.</span>
                        <span *ngIf="false" class="text-success">Connessione riuscita!</span>
                    </div>
                    <div class="col-lg-4 border-left text-xs">
                        <h6>Suggerimenti</h6>
                        <b>Codice fiscale del delegato</b>
                        <p>Inserire il codice fiscale della persona delegata dall'azienda ad accedere ai dati presenti nel portale dell'Agenzia delle Entrate</p>
                        <b>Password</b>
                        <p>Inserire il codice fiscale della persona delegata dall'azienda ad accedere ai dati presenti nel portale dell'Agenzia delle Entrate</p>
                        <b>PIN</b>
                        <p>Inserire il PIN ricevuto dall'Agenzia delle Entrate (8 cifre)</p>
                        <b>Password iniziale</b>
                        <p>Inserire la prima password utilizzata (ciò consentirà al sistema l'auto-aggiornamento periodico della password)</p>
                    </div>
                -->
                </div>
            </div>

            <div class="tab-pane fade show active" *ngIf="tab=='2'">
                <div class="row">
                    <div class="col-lg-8">              
                        <table class="table table-sm">
                            <thead>
                                <th>Nome banca</th>
                                <th>IBAN</th>
                                <th>Metodo</th>
                                <th class="text-center">Default</th>
                                <th class="text-right"></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let b of banks">
                                    <td><input type="text" value="{{b.name}}" class="form-control form-control-sm" name="" placeholder="..." ></td>
                                    <td><input type="text" value="{{b.iban}}" class="form-control form-control-sm" name="" placeholder="..." ></td>
                                    <td>
                                        <select class="form-control form-control-sm form-select" name="" placeholder="..." >
                                            <option value="contanti">contanti</option>
                                        </select>
                                    </td>
                                    <td class="text-center">
                                        <i *ngIf="false" class="fa fa-2x fa-check-square text-success pointer"></i>
                                        <i *ngIf="true" class="far fa-2x fa-square text-light pointer"></i>
                                    </td>
                                    <td class="text-right"><button type="button" class="btn btn-default btn-sm text-danger" (click)="true"><i class="fa fa-trash"></i></button></td>
                                </tr>
                            </tbody>                            
                        </table>
                        <button type="button" class="btn btn-outline-success btn-block btn-sm mt-3" title="Elimina" (click)="true"><i class="fa fa-plus mr-2"></i>Aggiungi banca</button>
                    </div>
                    <div class="col-lg-4 border-left text-xs">
                        <h6>Suggerimenti</h6>
                        <p>Indica le banche che utilizzi ed il metodo di pagamento preferito per ognuna di esse. Indica qui anche la cassa (contanti).</p>
                        <b>Attenzione</b>
                        <p>Se si elimina o modifica il nome di una banca, alcuni dati associati ala banca potrebbero non essere più rintracciabili.</p>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade show active" *ngIf="tab=='3'">
                <div class="row">
                    <div class="col-lg-8">              
                        <table class="table table-sm">
                            <thead>
                                <th>Denominazione</th>
                                <th class="text-center">Default<br>Ingresso</th>
                                <th class="text-center">Default<br>Uscita</th>
                                <th class="text-right"></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of causals">
                                    <td><input type="text" value="{{c.description}}" class="form-control form-control-sm" name="" placeholder="..." ></td>
                                    <td class="text-center">
                                        <i *ngIf="false" class="fa fa-2x fa-check-square text-success pointer"></i>
                                        <i *ngIf="true" class="far fa-2x fa-square text-light pointer"></i>
                                    </td>
                                    <td class="text-center">
                                        <i *ngIf="false" class="fa fa-2x fa-check-square text-success pointer"></i>
                                        <i *ngIf="true" class="far fa-2x fa-square text-light pointer"></i>
                                    </td>
                                    <td class="text-right"><button type="button" class="btn btn-default btn-sm text-danger" (click)="true"><i class="fa fa-trash"></i></button></td>
                                </tr>
                            </tbody>                            
                        </table>
                        <button type="button" class="btn btn-outline-success btn-block btn-sm mt-3" title="Elimina" (click)="true"><i class="fa fa-plus mr-2"></i>Aggiungi causale</button>
                    </div>
                    <div class="col-lg-4 border-left text-xs">
                        <h6>Suggerimenti</h6>
                        <p>Indica le causali che utilizzi e quale di queste il sistema deve impostarla di default nei casi di documenti in ingresso (es. fatture di acquisto) o di docuemnti in uscita (es. fatture di vendita).</p>
                    </div>
                </div>
            </div>
            
            <div class="tab-pane fade show active" *ngIf="tab=='4'">
                <div class="row">
                    <div class="col-lg-8">              
                        <table class="table table-sm">
                            <thead>
                                <th>Nome</th>
                                <th class="text-center">Suffisso</th>
                                <th class="text-center">Default</th>
                                <th class="text-right"></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of sectionals">
                                    <td><input type="text" value="{{s.label}}" class="form-control form-control-sm" name="" placeholder="..." ></td>
                                    <td><input type="text" value="{{s.code}}" class="form-control form-control-sm" name="" placeholder="..." ></td>
                                    <td class="text-center">
                                        <i *ngIf="false" class="fa fa-2x fa-check-square text-success pointer"></i>
                                        <i *ngIf="true" class="far fa-2x fa-square text-light pointer"></i>
                                    </td>
                                    <td class="text-right"><button type="button" class="btn btn-default btn-sm text-danger" (click)="true"><i class="fa fa-trash"></i></button></td>
                                </tr>
                            </tbody>                            
                        </table>
                        <button type="button" class="btn btn-outline-success btn-block btn-sm mt-3" title="Elimina" (click)="true"><i class="fa fa-plus mr-2"></i>Aggiungi causale</button>
                    </div>
                    <div class="col-lg-4 border-left text-xs">
                        <h6>Suggerimenti</h6>
                        <p>Indica il nome ed il suffisso dei sezionali che utilizzi (Es. Vendite 001). Indica anche quale dei sezionali il sistema deve impostare per default.</p>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade show active" *ngIf="tab=='5'">
                <div class="row">
                    <div class="col-lg-8">
                        <i *ngIf="true" class="fa fa-2x fa-check-square text-success pointer"></i>
                        <i *ngIf="false" class="far fa-2x fa-square text-light pointer"></i>
                        <label class="ml-2">Applica ritenuta d'acconto</label>
                        <br>
                        <label>Codice ritenuta</label>
                        <select class="form-control form-control-sm form-select" name="">
                            <option value="RT01">RT01 Ritenuta persone fisiche</option>
                            <option value="RT02">RT02 Ritenuta persone giuridiche</option>
                        </select>
                        <label>Percentuale ritenuta</label>
                        <input type="number" max="100" value="20" class="form-control form-control-sm"  name="" > 
                        <br>                    
                        <i *ngIf="false" class="fa fa-2x fa-check-square text-success pointer"></i>
                        <i *ngIf="true" class="far fa-2x fa-square text-light pointer"></i>
                        <label class="ml-2">Applica contributi previdenziali</label>
                        <br>
                        <label>Codice cassa previdenziale</label>
                        <select class="form-control form-control-sm form-select" name="" >
                            <option value="TC01">TC01 Cassa Nazionale Previdenza e Assistenza Avvocati e Procuratori Legali</option>
                            <option value="TC02">TC02 Cassa Previdenza Dottori Commercialisti</option>
                            <option value="TC03">TC03 Cassa Previdenza e Assistenza Geometri</option>
                            <option value="TC04">TC04 Cassa Nazionale Previdenza e Assistenza Ingegneri e Architetti Liberi Professionisti</option>
                            <option value="TC05">TC05 Cassa Nazionale del Notariato</option>
                            <option value="TC06">TC06 Cassa Nazionale Previdenza e Assistenza Ragionieri e Periti Commerciali</option>
                            <option value="TC07">TC07 Ente Nazionale Assistenza Agenti e Rappresentanti di Commercio (ENASARCO)</option>
                            <option value="TC08">TC08 Ente Nazionale Previdenza e Assistenza Consulenti del Lavoro (ENPACL)</option>
                            <option value="TC09">TC09 Ente Nazionale Previdenza e Assistenza Medici (ENPAM)</option>
                            <option value="TC10">TC10 Ente Nazionale Previdenza e Assistenza Farmacisti (ENPAF)</option>
                            <option value="TC11">TC11 Ente Nazionale Previdenza e Assistenza Veterinari (ENPAV)</option>
                            <option value="TC12">TC12 Ente Nazionale Previdenza e Assistenza Impiegati dell’Agricoltura (ENPAIA)</option>
                            <option value="TC13">TC13 Fondo Previdenza Impiegati Imprese di Spedizione e Agenzie Marittime</option>
                            <option value="TC14">TC14 Istituto Nazionale Previdenza Giornalisti Italiani (INPGI)</option>
                            <option value="TC15">TC15 Opera Nazionale Assistenza Orfani Sanitari Italiani (ONAOSI)</option>
                            <option value="TC16">TC16 Cassa Autonoma Assistenza Integrativa Giornalisti Italiani (CASAGIT)</option>
                            <option value="TC17">TC17 Ente Previdenza Periti Industriali e Periti Industriali Laureati (EPPI)</option>
                            <option value="TC18">TC18 Ente Previdenza e Assistenza Pluricategoriale (EPAP)</option>
                            <option value="TC19">TC19 Ente Nazionale Previdenza e Assistenza Biologi (ENPAB)</option>
                            <option value="TC20">TC20 Ente Nazionale Previdenza e Assistenza Professione Infermieristica (ENPAPI)</option>
                            <option value="TC21">TC21 Ente Nazionale Previdenza e Assistenza Psicologi (ENPAP)</option>
                            <option value="TC22">TC22 INPS</option>
                        </select>
                        <label>Percentuale cassa</label>
                        <input type="number" max="100" value="" class="form-control form-control-sm"  name="" >
                        <label>IVA cassa</label>
                        <input type="number" max="100" value="22" class="form-control form-control-sm"  name="" >
                        
                    </div>
                    <div class="col-lg-4 border-left text-xs">
                        <h6>Suggerimenti</h6>
                        <p>Se le tue fatture richiedono l'applicazione prevalente dei contributi previdenziali e/o della ritenuta d'acconto in fattura, abilita le funzioni e indica le percentuali relative.</p>
                        
                    </div>
                </div>
            </div>

        </div>
    </div>

</app-settingwindow>
