<div class="modal-header">
    <b><i class="fa fa-cog mr-2"></i>{{title}}</b>
</div>  
<div class="modal-body">
    <ng-content #component></ng-content>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-outline-secondary" (click)="close()">Chiudi</button>
    <button class="btn btn-sm btn-success" (click)="save()"><i class="fa fa-save mr-2"></i>Salva</button>
</div>


