<section class="content">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==3" (click)="tab=3"  title="Fasi">
                <i class="fas fa-list-ol"></i><small class="d-none d-sm-inline-block ml-2">FASI</small><br><b class="d-none d-sm-inline-block">Impostazioni</b></a>
        </li>
        <li class="nav-item" *ngFor="let t of types">
            <a class="nav-link" [class.active]="tab==t.id" (click)="tab=t.id">
                <i class="fas fa-folder-open"></i><small class="d-none d-sm-inline-block ml-2">PRATICHE</small><br><b class="d-none d-sm-inline-block">{{t.name}}</b></a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent" >
        <ng-container *ngFor="let t of types">
            <div class="tab-pane show active" *ngIf="tab==t.id">
                <app-dossierlist [mode]="mode" [id_type]="t.id" (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-dossierlist>
            </div>
        </ng-container>
        <div class="tab-pane show active" *ngIf="tab==3">
            <app-dossierphases></app-dossierphases>
        </div>
    </div>
</section>