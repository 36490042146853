<app-windowlist [model]="this">
    <div class="row pt-1">
        <div class="col-lg-3">
            <small>
                <app-filterdate [date_type]="mode!='embedded'?1:100" [class]="'card p-1 mb-2'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <app-treecategories [class]="'card p-1'" [multiple]="true" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [showCountProduct]="false" [editButton]="false" #tree [list]="categories"></app-treecategories>
            </small>
        </div>
        <div class="col-lg-9 pl-lg-0" >
            <div class="card card-outline">
                <div class="row p-1">
                    <div class="col-12 text-right">
                        <button title="Aggiorna" (click)="getItems()"  class="btn btn-primary btn-xs float-right ml-1"><i class="fas fa-sync-alt"></i><span class="ml-2">Aggiorna</span></button>
                        <button title="Esporta" (click)="export()"  class="btn btn-warning btn-xs float-right ml-1"><i class="fas fa-print"></i></button>
                    </div>
                </div>
                <div class="p-0">
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-sm table-striped m-0 text-sm">
                            <thead>                                
                                <th>
                                    <!-- <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" /> -->
                                    Data
                                </th>
                                <th>Descrizione</th>
                                <th class="text-center">Qt</th>
                                <th>Magazzino</th>
                                <th>Documento</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of list">                                   
                                    <td>
                                        {{r.date | date:'dd/MM/yy'}}
                                    </td>
                                    <td>
                                        {{r.description }}<span *ngIf="r.sn">&nbsp;<div class="badge border">{{r.sn}}</div></span>
                                        &nbsp;<i class="fa fa-pencil-alt pointer" title="Visualizza" (click)="openProduct(r.id_product)"></i>
                                        <ng-container *ngIf="id_product==0">
                                            &nbsp;<i class="fa fa-boxes pointer" title="Visualizza" (click)="openHistory(r.id_product)"></i>
                                        </ng-container>
                                        <br/><small>{{r.note}}</small>
                                    </td>
                                    <td class="text-center">{{r.quantity}} {{r.unit}}</td>
                                    <td>
                                        {{r.inventoryname}}<br/>
                                        <small>{{r.username}}</small>
                                    </td>
                                    <td><small>
                                            <app-reference [reference]="r.reference" [table]="r.table"></app-reference>
                                        </small>
                                    </td>
                                    <td>
                                        <span *ngIf="r.type==1" title="Carico" class="badge badge-success help"><i class="fa fa-arrow-up fa-2x"></i></span>
                                        <span *ngIf="r.type==2" title="Scarico" class="badge badge-danger help"><i class="fa fa-arrow-down fa-2x"></i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>